import React, { useEffect, useState, useRef } from 'react';

import AddWeekend from './AddWeekend';
import ContractorForm from '../Common/ContractorForm';
import ContractorFormField from '../Common/ContractorForm/ContractorFormField';
import { AsyncAutocompleteField, SelectField } from '@jsluna/react';
import { Search } from '@jsluna/icons';
import FormAlerts from '../Common/ContractorForm/FormAlerts';
import { useApiClient } from '../../context/AppContext'
import { ContractorSearchItem } from '../../types/Entities/ContractorEscalation';
import contractorContactsApi from '../../api/contractorContactsApi';
import { commonContractorsDropdownType, addEditContractorType } from '../../types/Entities/NewContractorType';
import weekendOnCallApi from '../../api/weekendOnCallApi'

import { LevelType, DateTimeType, UserListType, UsersAndLevelList, ServiceType } from "../../types/Entities/WeekendOnCallType";

const WeekendOnCall = () => {
    const pathName = window.location.pathname

    const [showData, setShowData] = useState(false);
    const [errorAlert, setErrorAlert] = useState<string | false>(false);
    const [successAlert, setSuccessAlert] = useState<string | false>(false);

    const [serviceName, setServiceName] = useState('');
    const [dateAndTimeName, setDateAndTimeName] = useState('');
    const [companyProvider, setCompanyProvider] = useState<ServiceType>()

    const [showService, setShowService] = useState(false);
    const [showDateTime, setShowDateTime] = useState(false);

    const [serviceOptions, setServiceOptions] = useState<ServiceType[]>([]);
    const [dateAndTimeOptions, setDateAndTimeOptions] = useState<DateTimeType[]>([]);

    const [userList, setUserList] = useState<UsersAndLevelList>()

    const myFormRef = useRef<any>();
    const apiClient = useApiClient();

    const scrollToTop = () => document.querySelector('div.ln-o-container.content')?.scrollTo(0, 0);

    const clearAlerts = () => {
        setErrorAlert(false);
        setSuccessAlert(false);
    };

    const resetPage = (flag = true) => {
        clearAlerts();
        setShowData(false);
        setShowService(false);
        setShowDateTime(false);
        setServiceName('');
        setDateAndTimeName('')
        // if (flag) myFormRef.current?.clearForm();
        // document.querySelectorAll("button.ln-c-button.ln-c-input-group__action, button[aria-label='Clear the field'], button[aria-label='close menu']")
        //     .forEach(button => button.dispatchEvent(new Event('click', { bubbles: true })));
    };

    const loadContractorSearch = (value: string): Promise<ContractorSearchItem[]> => {
        if (!value) {
            setShowService(false);
            setShowData(false);
            return Promise.resolve([]);
        }
        return contractorContactsApi.findContractorContactsCollection(apiClient, value)
            .then(response => response.map(item => ({ label: item.label, value: item.value })))
            .catch(error => {
                setErrorAlert("Something went wrong!");
                scrollToTop();
                return [];
            });
    };

    const handleContractorSelect = (val: ContractorSearchItem | null) => {
        if (!val) {
            resetPage();
            return;
        }
        setCompanyProvider(val)
        weekendOnCallApi.getUsersAndLevels(apiClient, val.value)
            .then(res => {
                const levels = res.ddlLevel.map(x => ({ ...x, label: x.level, value: x.level }))
                const userList = res.ddlUserList.map(x => ({ ...x, isNew: null, label: x.name, value: x.name }))
                const userLst = {
                    ddlLevel: levels,
                    ddlUserList: userList
                }
                setUserList(userLst)
                if (pathName === '/add-weekend') {
                    setShowData(true)
                } else {
                    weekendOnCallApi.getServices(apiClient, val.value)
                        .then(res => {
                            setServiceOptions(res)
                            setShowService(true)
                            return res
                        })
                }
            })
            .catch(err => setErrorAlert(err))
    };

    const onChangeService = (val: string) => {
        setShowDateTime(false);
        const findContact = serviceOptions.find(x => x.value === val);
        setServiceName(val);
        if (userList)
            setUserList({ ...userList, service: val })
        setShowData(false);
        // if (!val) {
        //     return;
        // }
        if (findContact) {
            return weekendOnCallApi.getDateAndTime(apiClient, companyProvider ? companyProvider.value : '', val)
                .then(response => {
                    setDateAndTimeOptions(response)
                    setShowDateTime(true);
                    setDateAndTimeName('')
                });
        }
    };

    const onChangeDateAndTime = (dateAndTime: string,companyId:string ) => {
        setDateAndTimeName(dateAndTime)
        setShowData(false);
        // if (!dateAndTime) {
        //     return;
        // }

        if (!companyProvider?.value) {
            setErrorAlert("Company ID is missing. Please select a contractor first.");
            return;
        }
        const companyid1 = companyProvider.value;
        console.log(companyId , companyid1)
        const dateAndTimeList = dateAndTime.split('/')
        const startDateAndTime = dateAndTimeList[0].trim().replace(" ", "T")
        const endDateAndTime = dateAndTimeList[1].trim().replace(" ", "T")
        return weekendOnCallApi.getUserDetails(apiClient, dateAndTime, serviceName, companyId)
            .then(response => {
                if (userList) {
                    setUserList(() => ({ ...userList, startDateAndTime: startDateAndTime, endDateAndTime: endDateAndTime, users: response }))
                }
                setShowData(true)
            })
    }

    const handleData = (data: string, type: string, companyId:string) => {
        clearAlerts()
        if (type === 'ErrorAlert') {
            setErrorAlert(data)
        } else if (type === 'SuccessAlert') {
            setSuccessAlert(data)
        } else if (type === 'SuccessAlert-Add') {
            setShowData(false)
            setSuccessAlert(data)
            setShowData(true)
        }
        else if (type === 'SuccessAlert-Update') {
            setSuccessAlert('Weekend On-Call Details updated successfully.')
            setShowData(false)
            setShowDateTime(false)
            onChangeService(serviceName)
            // setShowData(true)
            // setDateAndTimeName(data)
            onChangeDateAndTime(data,companyId)
        } else {
            setShowData(false)
            onChangeDateAndTime(dateAndTimeName,companyId)

        }
    }


    return (
        <>
            <FormAlerts showError={errorAlert} showSuccess={successAlert} onClose={clearAlerts} />
            <div className='weekendOnCall' style={{ backgroundColor: 'white', padding: '0' }}>
                <ContractorForm onSave={() => resetPage(false)} onClear={() => resetPage(false)} >
                    <ContractorFormField label='Company / Provider'>
                        <div>
                            <AsyncAutocompleteField
                                label=''
                                role='search'
                                className='formInput'
                                name='contractor-search'
                                onSelect={handleContractorSelect}
                                onFocus={clearAlerts}
                                onChange={clearAlerts}
                                loadOptions={loadContractorSearch}
                                icon={<Search />}
                            />
                        </div>
                    </ContractorFormField>
                    <div>
                        {pathName === '/edit-weekend' && showService && (
                            <ContractorFormField label='Service'>
                                <SelectField
                                    name="contractorSelector"
                                    value={serviceName}
                                    placeholder="Please Select"
                                    noResultsMessage="No data found"
                                    options={serviceOptions}
                                    onFocus={clearAlerts}
                                    onChange={(e: any) => onChangeService(e.target.value)}
                                />
                            </ContractorFormField>
                        )}
                    </div>
                    {pathName === '/edit-weekend' && showDateTime && (
                        <div style={{ marginTop: '-10px' }}>
                            <ContractorFormField label="Start & End Date">
                                <SelectField
                                    name="contractorSelector"
                                    value={dateAndTimeName}
                                    placeholder="Please Select"
                                    noResultsMessage="No data found"
                                    options={dateAndTimeOptions}
                                    onFocus={clearAlerts}
                                    onChange={(e: any) => onChangeDateAndTime(e.target.value,companyProvider?.value || '')}
                                />
                            </ContractorFormField>
                        </div>
                    )}
                    {showData && (
                        <AddWeekend userList={userList} onData={handleData} />
                    )}
                </ContractorForm>
            </div>
        </>
    )
}

export default WeekendOnCall