import React, { Component, useState, useEffect } from 'react'
import ContractorFormField from '../../Common/ContractorForm/ContractorFormField'
import { AsyncAutocompleteField, Switch, GridItem, GridWrapper, FilledButton, OutlinedButton, TextInput, TextInputField, CheckboxField, AutocompleteField, SelectField, TextArea, Input } from '@jsluna/react'
import ContractorForm from '../../Common/ContractorForm';
import { Card } from "@jsluna/card";
import { Accordion } from "@jsluna/accordion";
import ConfirmationModal from '../../Common/ContractorForm/ConfirmationModal'
import FormAlerts from '../../Common/ContractorForm/FormAlerts'

import { LevelType, UserListType, UsersAndLevelList, AddComanyWeekendOnCall } from "../../../types/Entities/WeekendOnCallType";
import weekendOnCallApi from '../../../api/weekendOnCallApi'
// import ApiClient from 'src/api/ApiClient';
import { useApiClient } from '../../../context/AppContext'
import CSS from "csstype";

type AddWeekendProps = {
    userList: UsersAndLevelList | undefined;
    onData: any
};

const AddWeekend = ({ userList, onData }: AddWeekendProps) => {
    const errorStyle: CSS.Properties = {
        textAlign: "left",
        marginLeft: '-10px',
        marginBottom: '10px'
    }
    const duplicateUserList = { ...userList }
    const apiClient = useApiClient()
    const pathName = window.location.pathname
    const [currentDate, setCurrentDate] = useState('')
    const [modalState, setModalState] = useState<'savescm' | 'noChange' | 'discard' | false>(false)
    const [errorAlert, setErrorAlert] = useState<false | string>(false)
    const [successAlert, setSuccessAlert] = useState<false | string>(false)
    const [users, setUsers] = useState(userList?.ddlUserList ? [...userList?.ddlUserList] : [])
    const [startDateTime, setStartDateTime] = useState<string>(userList?.startDateAndTime ? userList?.startDateAndTime : '')
    const [endDateTime, setEndDateTime] = useState<string>(userList?.endDateAndTime ? userList?.endDateAndTime : '')
    const [defaultStartDate, setDefaultStartDateTime] = useState<string>('')
    const [defaultEndDate, setDefaultEndDateTime] = useState<string>('')
    const [service, setService] = useState<string>(userList?.service ? userList?.service : '')
    const [showInvalidDate, setShowInvalidDate] = useState(false)
    const [disableButtons, setDisableButtons] = useState(false)
    const [disableStartDate, setDisableStartDate] = useState(false)
    // const [duplicateUsers, setDuplicateUsers] = useState<UserListType[]>([
    //     {
    //         name: '',
    //         rowId: crypto.randomUUID().toString(),
    //         id: crypto.randomUUID().toString(),
    //         companyUserId: '',
    //         email: '',
    //         phone: '',
    //         notes: '',
    //         level: '',
    //         isNew: null
    //     }
    // ]);
    const [weekendsRow, setWeekendsRows] = useState<UserListType[]>([
        {
            name: '',
            rowId: crypto.randomUUID().toString(),
            id: crypto.randomUUID().toString(),
            companyUserId: '',
            email: '',
            phone: '',
            notes: '',
            level: '',
            isNew: 1
        }
    ]);

    const [levels, setLevel] = useState(userList?.ddlLevel) as unknown as LevelType[]


    useEffect(() => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        // const seconds = String(now.getSeconds()).padStart(2, '0');
        const todayDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
        setCurrentDate(todayDateTime)
        setDefaultStartDateTime(`${year}-${month}-${day}T00:00`)
        setDefaultEndDateTime(`${year}-${month}-${day}T23:59`)
        if (!startDateTime) {
            setStartDateTime(`${year}-${month}-${day}T00:00`)
            // const date = new Date(`${year}-${month}-${day}T23:59`);
            // date.setDate(date.getDate() + 2); 
            // const newDate = date.toISOString(); 
            setEndDateTime(`${year}-${month}-${day}T23:59`)
            // setDefaultEndDateTime(newDate.split('.')[0])
            // setEndDateTime(`${year}-${month}-${day}T23:59`)
        }
        if (pathName === '/edit-weekend' && userList?.users) {
            const temp = userList?.users.map(x => ({
                ...x,
                isNew: null,
                rowId: x.id
            }))
            // setDuplicateUsers([...temp])
            setDisableStartDate(new Date(todayDateTime).getTime() > new Date(startDateTime).getTime())
            setWeekendsRows(temp)
            setDisableButtons(new Date(todayDateTime).getTime() > new Date(endDateTime).getTime())
        }
    }, [])

    const clearAlerts = () => {
        // setErrorAlert(false);
        // setSuccessAlert(false);
    };

    const resetPage = (flag = true) => {
        clearAlerts();
    };
    const scrollToTop = () => document.querySelector('div.ln-o-container.content')?.scrollTo(0, 0)

    const addRowToGrid = () => {
        let newguid = crypto.randomUUID();
        setWeekendsRows([...weekendsRow, { id: '', rowId: newguid.toString(), companyUserId: '', name: '', email: '', phone: '', isNew: 1, notes: '', level: '' }])
    }

    const removeRowFromGrid = (event: React.MouseEvent<HTMLButtonElement>, rowIndex: string) => {
        let fmlist = [...weekendsRow]
        if (fmlist.length === 1) {
            return
        }
        const element = event.target as HTMLButtonElement;
        var foundIndex = fmlist.findIndex(x => x.rowId == rowIndex);
        // if (fmlist[foundIndex]['isNew'] === 1) {
        //     const wONCall = fmlist.filter(x => x.rowId !== rowIndex)
        //     setWeekendsRows(wONCall);
        //     return
        // }

        if (fmlist[foundIndex].name !== "") {
            onData(`You have removed ${fmlist[foundIndex].name}. To save the changes, please click on the Save button.`, 'ErrorAlert')
            scrollToTop();
        }
        if (fmlist[foundIndex]['isNew'] === 1) {
            // onData(`You have removed ${fmlist[foundIndex].name}. To save the changes, please click on the Save button.`, 'ErrorAlert')
            fmlist.splice(foundIndex, 1);
        }
        else {
            // onData(`You have removed ${fmlist[foundIndex].name}. To save the changes, please click on the Save button.`, 'ErrorAlert')
            fmlist[foundIndex]['isNew'] = 0;

        }
        setWeekendsRows(fmlist);
        // setSuccessAlert(false)
    }

    const textInputOnChange = (event: any, rowIndex: string) => {
        const element = event.target;
        const validValue = event.target.value
        const scmlist = [...weekendsRow];
        let itemindex = scmlist.findIndex(i => i.rowId === rowIndex);

        scmlist[itemindex]['notes'] = element.value;
        setWeekendsRows(scmlist);
    }

    const handleDateOnSave = () => {
        if (showInvalidDate) {
            onData("Please ensure all fields are filled out correctly.", 'ErrorAlert')
            scrollToTop()
            return
        }
        if (!startDateTime || !endDateTime) {
            scrollToTop()
            onData('All fields marked with * are mandatory.', 'ErrorAlert')
            return
        }

        const strtDateTime = new Date(startDateTime).getTime()
        const edDateTime = new Date(endDateTime).getTime()
        const crntDateTime = new Date(currentDate).getTime()
        if(edDateTime < crntDateTime){
            scrollToTop()
            onData(`End Date should be greater then today Date.`, 'ErrorAlert')
            return
        }

        const numberOfRows = [...weekendsRow].filter((x: any) => x.name !== '').length
        if (numberOfRows < 1) {
            scrollToTop()
            onData('Please select atleast one user.', 'ErrorAlert')
            return
        }
        // if (numberOfRows !== weekendsRow.length) {
        //     scrollToTop()
        //     onData("Please ensure all fields are filled out correctly.", 'ErrorAlert')
        //     return
        // }

        // const startDate1 = userList?.startDateAndTime ? new Date(userList?.startDateAndTime).getTime() : ''
        // const startDate2 = new Date(startDateTime).getTime()
        // const endDtate1 = userList?.endDateAndTime ? new Date(userList?.endDateAndTime).getTime() : ''
        // const endDate2 = new Date(endDateTime).getTime()
        // const isUsersDataChanged = JSON.stringify(duplicateUsers) === JSON.stringify(weekendsRow)
        // console.log(duplicateUsers, weekendsRow)
        // if (isUsersDataChanged && startDate1 === startDate2 && endDtate1 === endDate2) {
        //     setModalState('noChange');
        //     return
        // }
        setModalState('savescm')
    }

    const handleSaveForm = () => {
        setModalState(false)
        const cmpnyONCallUD = weekendsRow.filter((x: any) => x.companyUserId !== '').map((x: any) => (
            x.isNew === 1 ?
                {
                    CompanyUserId: x.companyUserId,
                    level: x.level,
                    Notes: x.notes,
                    // isNew: x.isNew
                }
                :
                {
                    Id: x.id,
                    CompanyUserId: x.companyUserId,
                    level: x.level,
                    Notes: x.notes,
                    isNew: x.isNew
                }
        ))

        const payload = {
            CompanyId: users[0].companyUserId,
            Detail: service.trim(),
            StartDate: startDateTime.length < 19 ? startDateTime + ':00' : startDateTime,
            EndDate: endDateTime.length < 19 ? endDateTime + ':00' : endDateTime,
            CompanyOnCallUserDetails: cmpnyONCallUD
        }
        pathName === '/add-weekend'
            ? postAddWeekend(payload).then(scrollToTop)
            : updateWeekend(payload).then(scrollToTop)

    }

    const updateWeekend = (payload: AddComanyWeekendOnCall) => {
        return weekendOnCallApi.updateComanyWeekendOnCall(apiClient, payload)
            .then(async res => {
                if (res.ok) {
                    // onData('submit')
                    const payloadDate = payload.StartDate.replace('T', " ") + ' / ' + payload.EndDate.replace('T', " ")
                    console.log(payloadDate)
                    onData(payloadDate, 'SuccessAlert-Update')
                } else {
                    const errorData = await res.json();
                    if (errorData['BadRequest']) {
                        onData(errorData['BadRequest'], 'ErrorAlert')
                    } else {
                        onData('Something went wrong!', 'ErrorAlert')
                    }
                }
            })
            .catch(err => onData('Something went wrong!', 'ErrorAlert'))
    }

    const postAddWeekend = (payload: AddComanyWeekendOnCall) => {
        return weekendOnCallApi.addComanyWeekendOnCall(apiClient, payload)
            .then(async res => {
                if (res.ok) {
                    onData('Weekend On-Call Details added successfully.', 'SuccessAlert-Add')
                    let newguid = crypto.randomUUID();
                    setWeekendsRows([{ id: newguid.toString(), rowId: newguid.toString(), companyUserId: newguid.toString(), name: '', email: '', phone: '', isNew: 1, notes: '', level: '' }])
                    setService('')
                    setStartDateTime('')
                    setEndDateTime('')
                } else {
                    const errorData = await res.json();
                    if (errorData['BadRequest']) {
                        onData(errorData['BadRequest'], 'ErrorAlert')
                    } else {
                        onData('Something went wrong!', 'ErrorAlert')
                    }
                }
            })
            .catch(err => onData('Something went wrong!', 'ErrorAlert'))
    }

    const handleDiscardForm = () => {
        setModalState(false)
        // if (duplicateUsers) {
        if (pathName === '/add-weekend') {
            let newguid = crypto.randomUUID();
            setWeekendsRows([{ id: newguid.toString(), rowId: newguid.toString(), companyUserId: newguid.toString(), name: '', email: '', phone: '', isNew: 1, notes: '', level: '' }])
            setService('')
            setStartDateTime(defaultStartDate)
            setEndDateTime(defaultEndDate)
            return
        }
        onData('discard')
        // }-
    }

    const handleChange = (option: { target: { value: string; }; }, id: string, rowId: string) => {
        const findIndex = [...weekendsRow].findIndex(x => x.rowId == rowId)
        let WONCall = [...weekendsRow]
        WONCall[findIndex]['name'] = option.target.value
        setWeekendsRows(WONCall)
    };

    const handleSelect = (option: any, id: string, rowId: string) => {
        const findIndex = [...weekendsRow].findIndex(x => x.rowId == rowId)
        let WONCall = [...weekendsRow]
        if (!option) {
            WONCall = WONCall.map(x => x.rowId === rowId ?
                {
                    ...x, name: '', email: '', phone: '', companyUserId: ''
                }
                : x
            )
        } else {
            WONCall = WONCall.map(x => x.rowId === rowId ?
                {
                    ...x,
                    companyUserId: option.id,
                    name: option.name,
                    email: option.email,
                    phone: option.phone,
                    // notes: option.notes,
                    // level: option.level
                }
                : x
            )
        }
        setWeekendsRows(WONCall)

    };

    const truncateText = (text: string, malgength: number) => {
        if (text.length > malgength) {
            return text.substring(0, malgength) + '...';
        }
        return text;
    };

    const onChanageLevels = (value: string, rowIndex: string) => {
        const wONCall = weekendsRow.map(x =>
            x.rowId === rowIndex ? { ...x, level: value } : x
        );
        // wONCall.map(x => (x.rowId === rowIndex ?
        //     { ...x, level: value } : x))
        setWeekendsRows(wONCall)
    }

    const handleServiceChange = (value: string) => {
        const pattern = /^[A-Za-z0-9 ]*$/;
        if (pattern.test(value)) {
            setService(value.replace(/^\s+|\s+(?=\s)/g, ''))
        }
    }

    return (
        <>
            <ConfirmationModal
                showModal={modalState}
                onClose={() => setModalState(false)}
                onSave={handleSaveForm}
                onDiscard={handleDiscardForm}
            />

            {/* <FormAlerts
                showError={errorAlert}
                showSuccess={successAlert}
                onClose={clearAlerts}
            /> */}
            <div style={{ background: 'white', padding: '0' }}>
                {pathName === '/add-weekend' && (
                    <div>
                        <ContractorFormField label='Service'>
                            <TextInputField
                                onFocus={() => clearAlerts}
                                name="text-input-field-2"
                                onChange={(e: any) => { handleServiceChange(e.target.value) }}
                                // onChange={(e: any) => setService(e.target.value)}
                                value={service}
                                onBlur={(e: any) => clearAlerts}
                            />
                        </ContractorFormField>
                    </div>
                )}
                <div style={{ marginTop: '20px', marginLeft: '15px' }}>
                    {/* <div style={{ display: 'grid', gridTemplateColumns: '50% 50% 50%' }}> */}
                    {/* {pathName === '/add-weekend' && ( */}
                    <div >
                        <ContractorFormField label=''>
                            <div className='dateAndTime'>
                                <div>
                                    <label htmlFor="startDateTime"><b> StartDate & Time</b> <b style={{ color: 'red' }}>*</b></label>
                                    <Input type="datetime-local" name="startDateTime" id="startDateTime" style={{ padding: '5% 8%', width: '90%' }}
                                        value={startDateTime.length == 19 ? startDateTime.slice(0, -3) : startDateTime}
                                        min={currentDate}
                                        disabled={disableButtons || disableStartDate}
                                        // max={(startDateTime > currentDate) ? endDateTime : startDateTime}
                                        // onKeyDown={(e: any) => e.preventDefault()}
                                        onChange={(e: any) => {
                                            if (new Date(e.target.value).getTime() > new Date(endDateTime).getTime()) {
                                                if (!disableStartDate && !disableButtons) {
                                                    setStartDateTime(e.target.value)
                                                    setShowInvalidDate(true)
                                                }
                                            } else {
                                                setStartDateTime(e.target.value)
                                                setShowInvalidDate(false)
                                            }
                                        }}
                                        onFocus={(e: any) => {
                                            console.log('Input focused:', e.target.value);
                                        }}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="endDateTime"><b> EndDate & Time</b> <b style={{ color: 'red' }}>*</b></label>
                                    <Input type="datetime-local" name="endDateTime" id="endDateTime" style={{ padding: '5% 8%', width: '90%' }}
                                        value={endDateTime.length == 19 ? endDateTime.slice(0, -3) : endDateTime}
                                        min={new Date(currentDate).getTime() > new Date(startDateTime).getTime() ? currentDate : startDateTime}
                                        // max={'2099-12-30T23:59'}
                                        disabled={disableButtons}
                                        // onKeyDown={(e: any) => e.preventDefault()}
                                        onChange={(e: any) => {
                                            console.log(new Date(e.target.value).getTime() < new Date(startDateTime).getTime(), new Date(e.target.value).getTime(), new Date(startDateTime).getTime())
                                            if (new Date(e.target.value).getTime() < new Date(startDateTime).getTime()) {
                                                if (!disableButtons) {
                                                    // const date = e.target.value.split('T')[0]
                                                    // setEndDateTime(`${date}T23:59:00`)
                                                    setEndDateTime(e.target.value)
                                                    setShowInvalidDate(true)
                                                }
                                            } else {
                                                // const date = e.target.value.split('T')[0]
                                                // setEndDateTime(`${date}T23:59:00`)
                                                setEndDateTime(e.target.value)
                                                setShowInvalidDate(false)
                                            }
                                        }}
                                    />
                                </div>

                            </div>
                            {showInvalidDate &&
                                (
                                    <p className="ln-c-field-info ln-c-field-info--error" style={errorStyle}>The given Dates are invalid please check once!</p>
                                )}
                        </ContractorFormField>
                    </div>
                    {/* )} */}
                    {/* <hr /> */}
                    <Card>
                        <GridWrapper verticalAlign="center">
                            {/* <div data-testid="accordion-multiple"> */}
                            <div className='weekendHeadings'>
                                <GridItem className='formGridAlign textAlignLast_Center' size={{ lg: '1/5', md: '3/12', sm: '3/12', default: '3/12' }}><b>Name</b> <b style={{ color: 'red' }}>*</b></GridItem>
                                <GridItem className='formGridAlign textAlignLast_Center' size={{ lg: '1/8', md: '2/12', sm: '2/12', default: '2/12' }}><b>Level</b></GridItem>
                                <GridItem className='formGridAlign textAlignLast_Center' size={{ lg: '1/12', md: '1/12', sm: '2/12', default: '2/12' }}><b>Phone</b></GridItem>
                                <GridItem className='formGridAlign textAlignLast_Center' size={{ lg: '3/12', md: '4/12', sm: '2/12', default: '2/12' }}><b>Email</b></GridItem>
                                <GridItem className='formGridAlign textAlignLast_Center' size={{ lg: '2/12', md: '2/12', sm: '2/12', default: '2/12' }}><b>Note</b></GridItem>
                                <GridItem className='formGridAlign textAlignLast_Center' size={{ lg: '2/12', md: '1', sm: '1/12', default: '1/12' }}></GridItem>
                            </div>
                            {weekendsRow.filter((row: any) => row.isNew !== 0).map((row: any, index: any) => (
                                <div className='weekendContents'>
                                    <GridItem className='formGridAlign justifyItems' size={{ lg: '1/5', md: '3/12', sm: '5/12', ss: '6/12', xs: '7/12', default: '1/1' }}>
                                        <div className='dropDownAlign wknd_name' key={row.rowId}>
                                            <label>Name <b style={{ color: 'red' }}>*</b></label>
                                            <AutocompleteField
                                                placeholder={row.name ? row.name : 'Please Select'}
                                                name={row.name}
                                                // value={row.name}
                                                options={users}
                                                noResultsMessage="No stores found"
                                                onChange={(users: any) => handleChange(users, row.id, row.rowId)}
                                                onSelect={(users: any) => handleSelect(users, row.id, row.rowId)}
                                                disabled={disableButtons}
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem className='formGridAlign textAlignLast_Center' size={{ lg: '1/8', md: '2/12', sm: '3/12', ss: '6/12', xs: '5/12', default: '1/1' }}>
                                        <div className='dropDownAlign wknd_level'>
                                            <label>Level </label>
                                            <SelectField
                                                name="contractorSelector"
                                                value={row.level}
                                                placeholder="Please Select"
                                                noResultsMessage="No data found"
                                                options={levels}
                                                onFocus={clearAlerts}
                                                onChange={(e: any) => onChanageLevels(e.target.value, row.rowId)}
                                                disabled={disableButtons}
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem className='formGridAlign textAlignLast_Center' size={{ lg: '1/12', md: '1/12', sm: '4/12', ss: '3/12', xs: '4/12', default: '1/1' }}>
                                        <div style={{ width: 'max-content' }} className='rowTextAlign wknd_phone'>
                                            <label>Phone </label>
                                            <p>{row.phone}</p>
                                        </div>
                                    </GridItem>
                                    <GridItem className='formGridAlign textAlignLast_Center' size={{ lg: '3/12', md: '4/12', sm: '4/12', ss: '4/12', xs: '8/12', default: '1/1' }}>
                                        <div className='rowTextAlign wknd_email'>
                                            <label>Email </label>
                                            <p>
                                                {truncateText(row.email ? row.email : '', 25)}
                                            </p>
                                        </div>
                                    </GridItem>
                                    <GridItem className='formGridAlign' size={{ lg: '2/12', md: '2/12', sm: '4/12', ss: '5/12', xs: '6/12', default: '1/1' }}>
                                        {/* <div className='rowTextAlign'> */}
                                        <div className='wknd_notes'>
                                            <label>Notes </label>
                                            <TextInputField
                                                onFocus={() => clearAlerts()}
                                                id={row.id}
                                                value={row.notes}
                                                name="notes"
                                                placeholder='Notes'
                                                onChange={(e: any) =>
                                                    textInputOnChange(e, row.rowId)
                                                }
                                                className="formInput"
                                            />
                                        </div>
                                        {/* </div> */}
                                    </GridItem>
                                    <GridItem className='formGridAlign xxx' size={{ lg: '2/12', md: '1/1', sm: '4/12', ss: '1/1', xs: '6/12', default: '1/1' }}>
                                        <div className="formButtonGroup buttonTopAlign">
                                            {weekendsRow.filter((x: any) => x.isNew != 0).length > 1 &&
                                                <OutlinedButton
                                                    id='removeButton'
                                                    disabled={disableButtons}
                                                    fullWidth onClick={(e: React.MouseEvent<HTMLButtonElement>) => removeRowFromGrid(e, row.rowId)}
                                                >Remove </OutlinedButton>
                                            }
                                            {weekendsRow.filter((x: any) => x.isNew != 0).length == (index + 1) &&
                                                <FilledButton
                                                    id='addButton'
                                                    disabled={disableButtons}
                                                    fullWidth onClick={addRowToGrid} style={{ paddingLeft: '5px' }}
                                                >Add</FilledButton>
                                            }
                                        </div>
                                    </GridItem>
                                    {/* <GridItem size={{ lg: '1/12', ss: '6/12'  xs: '6/12',,default: '1/1' }}>1/12</GridItem> */}
                                </div>
                            ))}
                            {/* <Accordion
                                ss: '6/12' ,defaultOpen={true}
                                title=""
                                multipleOpen
                                titleElement="h3"
                            >
                                <ContractorFormField label=''>
                                    <table className="workorderdetails">
                                        <tbody> */}
                            {/* <tr key='row-Index'>
                                                <th>Name</th>
                                                <th>Level</th>
                                                <th>Phone</th>
                                                <th>Email</th>
                                                <th>Note</th>
                                                <th></th>
                                            </tr> */}
                            {/* {weekendsRow.filter((row: any) => row.isNew !== 0).map((row: any, index: any) => ( */}
                            {/* <> */}
                            {/* <tr> */}
                            {/* </tr> */}
                            {/* <tr key={row.rowId}>
                                                        <td>
                                                            <div style={{ marginTop: '10px', marginBottom: '-10px' }} key={row.rowId}>
                                                                <AutocompleteField
                                                                    placeholder={row.name ? row.name : 'Please Select'}
                                                                    name={row.name}
                                                                    // value={row.name}
                                                                    options={users}
                                                                    noResultsMessage="No stores found"
                                                                    onChange={(users: any) => handleChange(users, row.id, row.rowId)}
                                                                    onSelect={(users: any) => handleSelect(users, row.id, row.rowId)}
                                                                />
                                                            </div>
                                                        </td>
                                                        <>
                                                            <td>
                                                                <div style={index === 0 ? { marginTop: '12px', marginBottom: '-12px' } : { marginTop: '12px', marginBottom: '-12px' }}>
                                                                    <SelectField
                                                                        name="contractorSelector"
                                                                        value={row.level}
                                                                        placeholder="Please Select"
                                                                        noResultsMessage="No data found"
                                                                        options={levels}
                                                                        onFocus={clearAlerts}
                                                                        onChange={(e: any) => onChanageLevels(e.target.value, row.rowId)}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {row.phone}
                                                            </td>
                                                            <td>
                                                                {row?.email}
                                                            </td>
                                                            <td >
                                                                <div >
                                                                    {truncateText(row.notes ? row.notes : '', 30)}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='formButtonGroup'>
                                                                    {weekendsRow.filter((x: any) => x.isNew != 0).length > 1 &&
                                                                        <OutlinedButton id='id' size='60px' className='gridRemoveRowButton' onClick={(e: React.MouseEvent<HTMLButtonElement>) => removeRowFromGrid(e, row.rowId)} >Remove</OutlinedButton>
                                                                    }
                                                                    {weekendsRow.filter((x: any) => x.isNew != 0).length == (index + 1) &&
                                                                        <FilledButton size='60px' onClick={addRowToGrid} className='gridAddRowButton' >Add</FilledButton>
                                                                    }
                                                                </div>
                                                            </td>
                                                        </>
                                                    </tr> */}
                            {/* </> */}
                            {/* ))}
                                         </tbody>
                                     </table>
                                 </ContractorFormField>
                             </Accordion> */}
                            <>
                                <div className='formButtonGroup' >
                                    {/* <GridItem > */}
                                    <OutlinedButton className='formDiscardButton' disabled={disableButtons} onClick={() => { setModalState('discard') }}>Discard</OutlinedButton>
                                    {/* </GridItem> */}
                                    {/* <GridItem > */}
                                    <FilledButton className='formSaveButton' disabled={disableButtons} onClick={() => { handleDateOnSave() }}>Save</FilledButton>
                                    {/* </GridItem> */}
                                </div>
                            </>
                        </GridWrapper>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default AddWeekend