import React, { useEffect, useState, useRef } from 'react';
import { Card } from '@jsluna/card';
import AddContractors from './AddContractors';
import ContractorForm from '../Common/ContractorForm';
import ContractorFormField from '../Common/ContractorForm/ContractorFormField';
import { AsyncAutocompleteField, SelectField } from '@jsluna/react';
import { Search } from '@jsluna/icons';
import contractorContactsApi from '../../api/contractorContactsApi';
import { ContractorSearchItem, ContractorEscalation } from '../../types/Entities/ContractorEscalation';
import { useApiClient } from '../../context/AppContext';
import FormAlerts from '../Common/ContractorForm/FormAlerts';
import { addEditContractorType, commonContractorsDropdownType } from '../../types/Entities/NewContractorType';

const ContractorContact = () => {
  const defaultContractorContactEnt = {
    id: '',
    companyId: '',
    contract: '',
    detail: '',
    ooH_Dispatch_Verisae: false,
    ooH_Dispatch_Phone: false,
    ooH_Dispatch_Email: false,
    notes: '',
    officeOpenWeekdays: false,
    twentyFourHoursWeekdays: false,
    officeHoursWeekdayStart: null,
    officeHoursWeekdayEnd: null,
    officeOpenWeekends: false,
    twentyFourHoursWeekends: false,
    officeHoursWeekendStart: '',
    officeHoursWeekendEnd: '',
    verisaeOOHAccess: false,
    ooH_Dispatch_Number: '',
    ooH_Dispatch_Contact: '',
    contactDetails: []
  };

  const [contractorContactEnt, setContractorContactEnt] = useState(defaultContractorContactEnt as unknown as addEditContractorType);
  const [showData, setShowData] = useState(false);
  const [errorAlert, setErrorAlert] = useState<string | false>(false);
  const [successAlert, setSuccessAlert] = useState<string | false>(false);
  const [contactOptions, setContactOptions] = useState<commonContractorsDropdownType[]>([]);
  const [detailOptions, setDetailOptions] = useState<commonContractorsDropdownType[]>([]);
  const [contactName, setContactName] = useState('');
  const [detailName, setDetailName] = useState('');
  const [showContacts, setShowContacts] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const pathName = window.location.pathname;
  const myFormRef = useRef<any>();
  const apiClient = useApiClient();

  const scrollToTop = () => document.querySelector('div.ln-o-container.content')?.scrollTo(0, 0);

  const loadContractorSearch = (value: string): Promise<ContractorSearchItem[]> => {
    if (!value) {
      setShowDetails(false);
      setShowData(false);
      return Promise.resolve([]);
    }
    return contractorContactsApi.findContractorContactsCollection(apiClient, value)
      .then(response => response.map(item => ({ label: item.label, value: item.value })))
      .catch(error => {
        setErrorAlert("Something went wrong!");
        scrollToTop();
        return [];
      });
  };

  const handleContractorSelect = (val: ContractorSearchItem | null) => {
    if (!val) {
      resetPage();
      return;
    }
    contractorContactsApi.getContacts(apiClient, val.value)
      .then(response => {
        if (response) {
          setContactOptions(response.ddlContract);
          setContractorContactEnt((prev: addEditContractorType) => ({ ...prev, companyId: val.value }));
          setShowContacts(true);
          if (pathName === '/add-standard-contacts') {
            setShowData(true);
          } else if (pathName === '/edit-standard-contacts' && response.ddlContract.length === 1) {
            const firstContract = response.ddlContract[0];
            if (!firstContract.label) {
              setShowContacts(false);
            }
            setContactName(firstContract.label);
            return contractorContactsApi.getContractorContactsDetails(apiClient, firstContract.value)
              .then(details => {
                setShowData(true);
                setContractorContactEnt(details);
              });
          }
        } else {
          throw new Error('Invalid response.');
        }
      })
      .catch(error => {
        setErrorAlert('Something went wrong!');
        scrollToTop();
      });
  };

  const resetPage = (flag = true) => {
    clearAlerts();
    setShowData(false);
    setShowDetails(false);
    setShowContacts(false);
    setContactName('');
    setDetailName('');
    if (flag) myFormRef.current?.clearForm();
    document.querySelectorAll("button.ln-c-button.ln-c-input-group__action, button[aria-label='Clear the field'], button[aria-label='close menu']")
      .forEach(button => button.dispatchEvent(new Event('click', { bubbles: true })));
  };

  const clearAlerts = () => {
    setErrorAlert(false);
    setSuccessAlert(false);
  };

  const handleChildData = (data: string, type: string) => {
    clearAlerts();
    if (type === 'SuccessAlert') {
      setSuccessAlert(data);
      scrollToTop();
    } else if (type === 'ErrorAlert') {
      setErrorAlert(data);
      scrollToTop();
    } else if (type === 'discard') {
      setShowData(false);
      handleDiscard(data);
    }
  };

  const handleDiscard = (data: string) => {
    if (pathName === '/add-standard-contacts') {
      contractorContactsApi.getContacts(apiClient, data)
        .then(response => {
          if (response) {
            setContactOptions(response.ddlContract);
            setContractorContactEnt((prev: addEditContractorType) => ({ ...prev }));
            setShowData(true);
          } else {
            throw new Error('Invalid response.');
          }
        })
        .catch(error => {
          setErrorAlert('Something went wrong!');
          scrollToTop();
        });
    } else {
      onChangeEditDetails(data);
    }
  };

  const onChangeEditDetails = (val: string) => {
    clearAlerts();
    setDetailName(val);
    setShowData(false);
    if (!val) {
      const findContact = contactOptions.find(x => x.value === contactName);
      return contractorContactsApi.getContractorContactsDetails(apiClient, findContact ? findContact.value : '')
        .then(response => {
          setContractorContactEnt(response);
          setShowData(true);
        });
    }
    return contractorContactsApi.getContractorContactsDetails(apiClient, val)
      .then(response => {
        setContractorContactEnt(response);
        setShowData(true);
      });
  };

  const onChangeEditContactDetails = (val: string) => {
    const findContact = contactOptions.find(x => x.value === val);
    setContactName(val);
    setShowData(false);
    if (!val) {
      setShowDetails(false);
      return;
    }
    if (findContact) {
      return contractorContactsApi.getDetails(apiClient, findContact.label, contractorContactEnt.companyId)
        .then(response => {
          setShowDetails(!!findContact.label);
          setDetailName('');
          setDetailOptions(response.ddlDetail);
          if (!response.ddlDetail.length) {
            setShowDetails(false);
            return contractorContactsApi.getContractorContactsDetails(apiClient, findContact.value)
              .then(details => {
                setContractorContactEnt(details);
                setShowData(true);
              });
          }
        });
    }
  };

  return (
    <>
      <FormAlerts showError={errorAlert} showSuccess={successAlert} onClose={clearAlerts} />
      <div className='contractors'>
        <ContractorForm onSave={() => resetPage(false)} onClear={() => resetPage(false)}>
          <ContractorFormField label='Contractor Name'>
            <AsyncAutocompleteField
              label=''
              role='search'
              className='formInput'
              name='contractor-search'
              onSelect={handleContractorSelect}
              onFocus={clearAlerts}
              onChange={clearAlerts}
              loadOptions={loadContractorSearch}
              icon={<Search />}
            />
          </ContractorFormField>
          <div>
            {pathName === '/edit-standard-contacts' && showContacts && (
              <ContractorFormField label='Service'>
                <SelectField
                  name="contractorSelector"
                  value={contactName}
                  placeholder="Please Select"
                  noResultsMessage="No data found"
                  options={contactOptions}
                  onFocus={clearAlerts}
                  onChange={(e: any) => onChangeEditContactDetails(e.target.value)}
                />
              </ContractorFormField>
            )}
          </div>
          {pathName === '/edit-standard-contacts' && showDetails && (
            <div style={{ marginTop: '-10px' }}>
              <ContractorFormField label='Detail'>
                <SelectField
                  name="contractorSelector"
                  value={detailName}
                  placeholder="Please Select"
                  noResultsMessage="No data found"
                  options={detailOptions}
                  onFocus={clearAlerts}
                  onChange={(e: any) => onChangeEditDetails(e.target.value)}
                />
              </ContractorFormField>
            </div>
          )}
          {showData && (
            <AddContractors contractors={contractorContactEnt} onData={handleChildData} />
          )}
        </ContractorForm>
      </div>
    </>
  );
};

export default ContractorContact;