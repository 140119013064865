import React, { useRef, useState } from 'react'
import { AsyncAutocompleteField } from '@jsluna/react'
import { Search } from '@jsluna/icons'

import ContractorForm from '../Common/ContractorForm'
import ContractorFormField from '../Common/ContractorForm/ContractorFormField'

import contractorContactsApi from '../../api/contractorContactsApi'
import { ContractorSearchItem, ContractorEscalation } from '../../types/Entities/ContractorEscalation'
import { ContractorFormData } from '../../types/FormTypes'
import { useApiClient } from '../../context/AppContext'

const ContractorsEscalation = () => {
  const [selectedContractor, setSelectedContractor] = useState({ id: '', contractorName: '' } as ContractorEscalation)
  const [errorAlert, setErrorAlert] = useState<false | string>(false)
  const [successAlert, setSuccessAlert] = useState<false | string>(false)

  const myFormRef: any = useRef()

  const apiClient = useApiClient()

  const scrollToTop = () => document.querySelector('div.ln-o-container.content')?.scrollTo(0, 0)

  const loadContractorSearch = async (value: string): Promise<ContractorSearchItem[]> => {
    return contractorContactsApi
      .findContractorContacts(apiClient, value)
      .then((response) =>
        response.map((item) => ({
          label: item.contractorName,
          value: item.id,
        }))
      )
      .catch(error => {
        console.log(error)
        setErrorAlert('Something went wrong!')
        scrollToTop()
        const event = new Event('click', { bubbles: true })
        document.querySelector("button.ln-c-button.ln-c-input-group__action, button[aria-label='close menu']")?.dispatchEvent(event)
        return Promise.resolve([])
      })
  }

  const handleContractorSelect = (val: ContractorSearchItem) => {
    if (val === null) {
      resetPage()
    }
    else {
      contractorContactsApi
        .getContractorContacts(apiClient, val.value)
        .then((response) => {
          if (response && response.id) {
            setSelectedContractor(response)
            myFormRef.current?.setContractorEmail({ value: response.contractorEmailId ?? '', error: '' })
            myFormRef.current?.setEscalationValue(0, response.escalationLevel1?.split(',') ?? [])
            myFormRef.current?.setEscalationValue(1, response.escalationLevel2?.split(',') ?? [])
            myFormRef.current?.setEscalationValue(2, response.escalationLevel3?.split(',') ?? [])
            myFormRef.current?.setEscalationValue(3, response.escalationLevel4?.split(',') ?? [])
            myFormRef.current?.setEscalationValue(4, response.escalationLevel5?.split(',') ?? [])
          }
          else {
            throw new Error('Invalid response.')
          }
        })
        .catch(error => {
          console.log(error)
          setErrorAlert('Something went wrong!')
          scrollToTop()
        })
    }
  }

  const handleSaveForm = (data: ContractorFormData) => {
    var payload = selectedContractor
    payload.contractorEmailId = data.contractorEmail.value
    payload.escalationLevel1 = data.escalations[0].value.join(',')
    payload.escalationLevel2 = data.escalations[1].value.join(',')
    payload.escalationLevel3 = data.escalations[2].value.join(',')
    payload.escalationLevel4 = data.escalations[3].value.join(',')
    payload.escalationLevel5 = data.escalations[4].value.join(',')

    contractorContactsApi
      .updateContractorContacts(apiClient, payload)
      .then(response => {
        if (response.ok) {
          setSuccessAlert('Contractor Contacts have been updated successfully.')
          setErrorAlert(false)
          resetPage()
          scrollToTop()
        }
        else {
          throw new Error('Invalid response.')
        }
      })
      .catch(error => {
        console.log(error)
        setErrorAlert('Updating Contractor Contacts Failed.')
        setSuccessAlert(false)
        scrollToTop()
      })
  }

  const resetPage = (flag: boolean = true) => {
    setErrorAlert(false)
    setSelectedContractor({ id: '', contractorName: '' } as ContractorEscalation)
    if (flag) myFormRef.current?.clearForm()

    const event = new Event('click', { bubbles: true })
    document.querySelector("button.ln-c-button.ln-c-input-group__action, button[aria-label='Clear the field']")?.dispatchEvent(event)
    document.querySelector("button.ln-c-button.ln-c-input-group__action, button[aria-label='close menu']")?.dispatchEvent(event)
  }

  const clearAlerts = () => {
    setErrorAlert(false)
    setSuccessAlert(false)
  }

  return (
    <ContractorForm
      ref={myFormRef}
      contractorEmail={selectedContractor.id !== ''}
      contractorEscalations={selectedContractor.id !== ''}
      onSave={handleSaveForm}
      errorAlert={errorAlert}
      successAlert={successAlert}
      onAlertClose={clearAlerts}
      onClear={() => resetPage(false)}
    >
      <ContractorFormField label='Contractor Name'>
        <AsyncAutocompleteField
          label=''
          role='search'
          className='formInput'
          name='contractor-search'
          onSelect={handleContractorSelect}
          onFocus={() => clearAlerts()}
          onChange={() => clearAlerts()}
          loadOptions={loadContractorSearch}
          icon={<Search />}
        />
      </ContractorFormField>
    </ContractorForm>
  )
}

export default ContractorsEscalation