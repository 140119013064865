import React, { useEffect, useRef, useState } from 'react'
import ContractorForm from '../../Common/ContractorForm'
import { Search } from '@jsluna/icons'
import CSS from "csstype";
import { Card } from "@jsluna/card";
import { TextAreaField } from "@jsluna/form";
import { AsyncAutocompleteField, Switch, GridItem, GridWrapper, FilledButton, OutlinedButton, TextInput, TextInputField, CheckboxField, AutocompleteField, SelectField, TextArea } from '@jsluna/react'


import ContractorFormField from '../../Common/ContractorForm/ContractorFormField'

import contractorContactsApi from '../../../api/contractorContactsApi'
import { useApiClient } from '../../../context/AppContext'
import ConfirmationModal from '../../Common/ContractorForm/ConfirmationModal'
import { selectedOptionEntity, addEditContractorType, contactDetailsType, postContractorType } from '../../../types/Entities/NewContractorType'


const AddContractors = (props: { contractors: addEditContractorType, onData: any }) => {

  const errorStyle: CSS.Properties = {
    textAlign: "left",
    fontSize: "small",
    marginLeft: '20px',
    marginTop: '-15px',
    marginBottom: '10px'
  }
  const primaryContractorsByHeader = {
    id: props.contractors.id,
    companyId: props.contractors.companyId,
    contract: props.contractors.contract,
    detail: props.contractors.detail,
    ooH_Dispatch_Verisae: props.contractors.ooH_Dispatch_Verisae ? props.contractors.ooH_Dispatch_Verisae : false,
    // ooH_Dispatch_Phone : props.contractors.ooH_Dispatch_Phone ? props.contractors.ooH_Dispatch_Phone : false,
    // ooH_Dispatch_Email : props.contractors.ooH_Dispatch_Email ? props.contractors.ooH_Dispatch_Email : false,
    notes: props.contractors.notes,
    officeHoursWeekdayStart: props.contractors.officeHoursWeekdayStart === "00:00:00"
      ? "00:00"
      : props.contractors.officeHoursWeekdayStart
        ? props.contractors.officeHoursWeekdayStart
        : '',
    officeHoursWeekdayEnd: props.contractors.officeHoursWeekdayEnd === "00:00:00"
      ? "00:00"
      : props.contractors.officeHoursWeekdayEnd ? props.contractors.officeHoursWeekdayEnd : '',
    officeHoursWeekendStart: props.contractors.officeHoursWeekendStart === "00:00:00"
      ? "00:00"
      : props.contractors.officeHoursWeekendStart ? props.contractors.officeHoursWeekendStart : '',
    officeHoursWeekendEnd: props.contractors.officeHoursWeekendEnd === "00:00:00"
      ? "00:00"
      : props.contractors.officeHoursWeekendEnd ? props.contractors.officeHoursWeekendEnd : '',
    officeOpenWeekdays: props.contractors.officeOpenWeekdays,
    officeOpenWeekends: props.contractors.officeOpenWeekends,
    ooH_Dispatch_Contact: props.contractors.ooH_Dispatch_Contact,
    ooH_Dispatch_Number: props.contractors.ooH_Dispatch_Number,
    twentyFourHoursWeekdays: props.contractors.twentyFourHoursWeekdays,
    twentyFourHoursWeekends: props.contractors.twentyFourHoursWeekends,
    verisaeOOHAccess: props.contractors.verisaeOOHAccess ? props.contractors.verisaeOOHAccess : false,
    contactDetails: props.contractors.contactDetails.map(contact => {
      return {
        ...contact,
        label: contact.name,
        value: contact.name,
        isNew: null
      };
    })
  }
  const [contractorsByHeader, setContractorsByHeader] = useState({ ...primaryContractorsByHeader })
  const [isContract, setIsContract] = useState(false)
  const [isDetail, setIsDetail] = useState(false)
  const [showContractRequire, setShowContractRequire] = useState(false)
  const [selectedOption, setSelectedOption] = useState([...primaryContractorsByHeader.contactDetails] as selectedOptionEntity[]);
  const [dummySelectedOption, setDummySelectedOption] = useState([...primaryContractorsByHeader.contactDetails] as selectedOptionEntity[]);
  const [users, setUsers] = useState([] as selectedOptionEntity[])
  const [contactDetails, setContactDetails] = useState([] as contactDetailsType[])
  const [modalState, setModalState] = useState<'save' | 'discard' | 'noChange' | false>(false)
  const [options, setOptions] = useState([
    { id: '1', name: 'Director', label: "", },
    { id: '2', name: 'Primary', label: "", },
    { id: '3', name: 'Level 1', label: "", },
    { id: '4', name: 'Level 2', label: "", },
    { id: '5', name: 'Level 3', label: "", },
    { id: '6', name: 'Level 4', label: "", },
    { id: '7', name: 'Level 5', label: "", },
    { id: '8', name: 'Level 6', label: "", },
  ])
  const [duplicateOptions, setDuplicateOptions] = useState(options)
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [invalidPhone, setInvalidPhone] = useState(false)
  const [weekdaysStart, SetWeekdaysStart] = useState(false)
  const [weekdaysClose, SetWeekdaysClose] = useState(false)
  const [weekendsStart, SetWeekendsStart] = useState(false)
  const [weekendsClose, SetWeekendsClose] = useState(false)
  const [emailChecked, setEmailChecked] = useState(props.contractors.ooH_Dispatch_Contact ? props.contractors.ooH_Dispatch_Contact : false)
  const [phoneChecked, setPhoneChecked] = useState(props.contractors.ooH_Dispatch_Number ? props.contractors.ooH_Dispatch_Number : false)

  let dispatchOptions = [
    { value: "Verisae", label: "Verisae", defaultChecked: contractorsByHeader.ooH_Dispatch_Verisae },
    { value: "Email", label: "Email", defaultChecked: contractorsByHeader.ooH_Dispatch_Contact ? true : false },
    { value: "Phone", label: "Phone", defaultChecked: contractorsByHeader.ooH_Dispatch_Number ? true : false },
  ]

  const myFormRef: any = useRef()
  const apiClient = useApiClient()

  const scrollToTop = () => document.querySelector('div.ln-o-container.content')?.scrollTo(0, 0)
  const handleScrollModal = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    })
  }
  const pathName = window.location.pathname

  useEffect(() => {
    if (pathName === '/edit-standard-contacts') {
      loadContacts();
    }
    loadUsers();
  }, [])


  const loadUsers = () => {
    return contractorContactsApi.getContactUsers(apiClient, props.contractors.companyId)
      .then((response: selectedOptionEntity | selectedOptionEntity[]) => {
        const contactArray = Array.isArray(response) ? response : [response];
        const updatedContactDetails = contactArray.map((contact: selectedOptionEntity) => ({
          ...contact,
          label: contact.name,
          value: contact.name
        }));
        setUsers(updatedContactDetails);
      })
      .catch(error => props.onData('Something went Wrong!', 'ErrorAlert'))
  };


  const loadContacts = () => {
    // const updatedContactDetails = props.contractors.contactDetails.map(contact => ({
    //   ...contact,
    //   label: contact.name,
    //   value: contact.name,
    //   isNew: null
    // }));

    const updatedOptions = options.map(option => {
      const contact = props.contractors.contactDetails.find(detail => detail.level === option.name);
      return contact ? { ...option, label: contact.name } : option;
    });

    // setSelectedOption(updatedContactDetails)
    // setDummySelectedOption(updatedContactDetails)
    setOptions(updatedOptions);
    setDuplicateOptions(updatedOptions)
  }

  const handleChange = (option: { target: { value: any; }; }, id: string, name: string) => {
    props.onData()
    console.log(users)
    if (option) {
      const temp = [...options].map(opt =>
        opt.name === name ? { ...opt, label: option.target.value } : opt
      );
      console.log(option, temp, name);
      setOptions(temp);
    } else {
      const temp = [...options].map(opt =>
        opt.name === name ? { ...opt, label: '' } : opt
      );
      console.log(option, temp, name);
      setOptions(temp);
    }
  };

  const handleSelect = (option: selectedOptionEntity, id: string, name: string) => {
    props.onData()
    if (option) {
      let options = ''
      const checkType1 = dummySelectedOption.some(opt => opt.level === name);
      if (checkType1) {
        options = dummySelectedOption.filter(opt => opt.level === name)[0].id;
        console.log(options)
      }

      const dummy = {
        id: options,
        companyId: option.companyId,
        companyHeaderId: option.companyHeaderId,
        contactLevel: option.contactLevel,
        companyUserId: option.id,
        level: name,
        name: option.value || '',
        email: option.email,
        phone: option.phone,
        notes: option.notes,
        label: option.value,
        value: option.value,
        isNew: options ? null : 1
      };

      const updatedSelectedOption = selectedOption.map(opt =>
        opt.level === name ? { ...opt, ...dummy } : opt
      );

      const checkType = updatedSelectedOption.some(opt => opt.level === name);

      if (checkType) {
        setSelectedOption(updatedSelectedOption);
      } else {
        setSelectedOption(prev => [...prev, dummy]);
      }
    } else {
      const temp = options.map(opt =>
        opt.name === name ? { ...opt, label: '' } : opt
      );
      console.log(option, temp, name);
      setOptions(temp);

      const slctdOptions = selectedOption.filter(opt => opt.level !== name);
      console.log(slctdOptions);
      setSelectedOption(slctdOptions);
    }
  };

  const onChangeContactDetails = (value: any, type: string) => {
    props.onData()
    const pattern = /^[A-Za-z0-9 ]*$/;
    switch (type) {
      case 'contact':
        if (pattern.test(value)) {
          // setService()
          setContractorsByHeader({ ...contractorsByHeader, contract: value.replace(/^\s+|\s+(?=\s)/g, '') })
        }
        setShowContractRequire(false)
        break;
      case 'details':
        if (pattern.test(value)) {
          setContractorsByHeader({ ...contractorsByHeader, detail: value.replace(/^\s+|\s+(?=\s)/g, '') })
        }
        break;
    }
  }


  const onBlurContactDetails = (value: any, type: string) => {
    switch (type) {
      case 'contact':
        if (value.trim()) {
          setIsContract(true)
        } else {
          setIsContract(false)
        }
        break;
      case 'details':
        if (value.trim()) {
          setIsDetail(true)
        } else {
          setIsDetail(false)
        }
        break;
    }
  }


  const onChangeOfficeHours = (value: any, type: string) => {
    props.onData()
    switch (type) {
      case 'OPWeekends':
        setContractorsByHeader({ ...contractorsByHeader, officeOpenWeekends: value, officeHoursWeekendStart: '', officeHoursWeekendEnd: '' })
        SetWeekendsStart(false)
        SetWeekendsClose(false)
        break;
      case 'OPWeekdays':
        setContractorsByHeader({ ...contractorsByHeader, officeOpenWeekdays: value, officeHoursWeekdayStart: '', officeHoursWeekdayEnd: '' })
        SetWeekdaysStart(false)
        SetWeekdaysClose(false)
        break;
      case 'THWeekends':
        setContractorsByHeader({ ...contractorsByHeader, twentyFourHoursWeekends: value, officeHoursWeekendStart: '', officeHoursWeekendEnd: '' })
        SetWeekendsStart(false)
        SetWeekendsClose(false)
        break;
      case 'THWeekdays':
        setContractorsByHeader({ ...contractorsByHeader, twentyFourHoursWeekdays: value, officeHoursWeekdayStart: '', officeHoursWeekdayEnd: '' })
        SetWeekdaysStart(false)
        SetWeekdaysClose(false)
        break;
    }
  }

  const onChangeDispatch = (value: any, type: string) => {
    props.onData()
    switch (type) {
      case 'email':
        setContractorsByHeader({ ...contractorsByHeader, ooH_Dispatch_Contact: value })
        break;
      case 'phone':
        if (value.length <= 5) {
          setContractorsByHeader(prev => ({ ...contractorsByHeader, ooH_Dispatch_Number: value.replace(/\D/g, "") }))
        } else if (value.length > 12) {
          console.log("value exceed")
        } else {
          const phoneValue = `${value.slice(0, 5).replace(/\D/g, '')} ${value.slice(6, 13).replace(/\D/g, '')}`;
          setContractorsByHeader({ ...contractorsByHeader, ooH_Dispatch_Number: phoneValue })
        }
        break;

    }
  }

  const onChangeDispatchBox = (value: any) => {
    props.onData()
    switch (value.value) {
      case 'Email':
        setEmailChecked(value.checked)
        break;
      case 'Phone':
        setPhoneChecked(value.checked)
        break;
      case 'Verisae':
        setContractorsByHeader({ ...contractorsByHeader, ooH_Dispatch_Verisae: value.checked })
        break;

    }
  }


  const handleDiscardForm = () => {
    setModalState(false);
    props.onData(props.contractors.id ? props.contractors.id : props.contractors.companyId, 'discard')
    // setContractorsByHeader(primaryContractorsByHeader)
    // setOptions(duplicateOptions)
    // setInvalidEmail(false)
    // setInvalidPhone(false)
    // SetWeekdaysClose(false)
    // SetWeekdaysStart(false)
    // SetWeekendsClose(false)
    // SetWeekendsStart(false)
  }

  const handleDataOnSaveClick = () => {
    const checkPrimaryANDDirector = selectedOption.filter(slctdoption => slctdoption.level === 'Director' || slctdoption.level === 'Primary')
    if (invalidEmail || invalidPhone || weekdaysStart || weekdaysClose || weekendsClose || weekendsStart) {
      props.onData('Some fields are invalid!', 'ErrorAlert')
      scrollToTop();
      return
    }
    if (!isContract && isDetail) {
      props.onData('Service name is required !', 'ErrorAlert')
      scrollToTop();
      setShowContractRequire(true)
      return
    }
    const checkWeekDays = (!contractorsByHeader.officeOpenWeekdays) || (contractorsByHeader.officeOpenWeekdays && (contractorsByHeader.twentyFourHoursWeekdays || (contractorsByHeader.officeHoursWeekdayStart.length > 0 && contractorsByHeader.officeHoursWeekdayEnd.length > 0)))
    const checkWeekends = (!contractorsByHeader.officeOpenWeekends) || (contractorsByHeader.officeOpenWeekends && (contractorsByHeader.twentyFourHoursWeekends || (contractorsByHeader.officeHoursWeekendStart.length > 0 && contractorsByHeader.officeHoursWeekendEnd.length > 0)))
    if ((checkPrimaryANDDirector.length !== 2) || (!checkWeekDays) || (!checkWeekends)) {
      props.onData('* fields are required !', 'ErrorAlert')
      return
    }
    // console.log('contractorsByHeader', contractorsByHeader, 'primaryContractorsByHeader', primaryContractorsByHeader, 'contractorsByHeader === primaryContractorsByHeader', JSON.stringify(contractorsByHeader) === JSON.stringify(primaryContractorsByHeader), JSON.stringify(duplicateOptions) === JSON.stringify(selectedOption), JSON.stringify(duplicateOptions), JSON.stringify(selectedOption))
    // if ((JSON.stringify(contractorsByHeader) === JSON.stringify(primaryContractorsByHeader)) && (JSON.stringify(dummySelectedOption) === JSON.stringify(selectedOption))) {
    //   setModalState('noChange');
    //   return
    // }
    setModalState('save');
  }

  const handleSaveClick = () => {
    setModalState(false);
    const ContactDetails = pathName === '/add-standard-contacts'
      ? selectedOption.map(item => ({
        ContactLevel: item.level,
        CompanyUserId: item.companyUserId.trim(),
        Notes: item.notes || null
      }))
      : selectedOption.map(item => {
        if (item.isNew === null) {
          return {
            Id: item.id ? item.id : null,
            ContactLevel: item.level,
            CompanyUserId: item.companyUserId.trim(),
            Notes: item.notes || null
          };
        } else {
          return {
            ContactLevel: item.level,
            CompanyUserId: item.companyUserId.trim(),
            Notes: item.notes || null
          };
        }
      });
    let payload = {
      CompanyId: props.contractors.companyId,
      Contract: contractorsByHeader.contract.trim(),
      Detail: contractorsByHeader.detail.trim(),
      VerisaeOOHAccess: contractorsByHeader.verisaeOOHAccess,
      OOH_Dispatch_Verisae: contractorsByHeader.ooH_Dispatch_Verisae,
      Notes: contractorsByHeader.notes,
      OfficeOpenWeekdays: contractorsByHeader.officeOpenWeekdays,
      TwentyFourHoursWeekdays: contractorsByHeader.twentyFourHoursWeekdays,
      OfficeHoursWeekdayStart: contractorsByHeader.officeHoursWeekdayStart === "" || contractorsByHeader.officeHoursWeekdayStart === null ? null
        : contractorsByHeader.officeHoursWeekdayStart.length === 8
          ? contractorsByHeader.officeHoursWeekdayStart
          : contractorsByHeader.officeHoursWeekdayStart + ':00',
      OfficeHoursWeekdayEnd: contractorsByHeader.officeHoursWeekdayEnd === "" || contractorsByHeader.officeHoursWeekdayEnd === null ? null
        : contractorsByHeader.officeHoursWeekdayEnd.length === 8
          ? contractorsByHeader.officeHoursWeekdayEnd
          : contractorsByHeader.officeHoursWeekdayEnd + ':00',
      OfficeOpenWeekends: contractorsByHeader.officeOpenWeekends,
      TwentyFourHoursWeekends: contractorsByHeader.twentyFourHoursWeekends,
      OfficeHoursWeekendStart: contractorsByHeader.officeHoursWeekendStart === "" || contractorsByHeader.officeHoursWeekendStart === null ? null
        : contractorsByHeader.officeHoursWeekendStart.length === 8
          ? contractorsByHeader.officeHoursWeekendStart
          : contractorsByHeader.officeHoursWeekendStart + ':00',
      OfficeHoursWeekendEnd: contractorsByHeader.officeHoursWeekendEnd === "" || contractorsByHeader.officeHoursWeekendEnd === null ? null
        : contractorsByHeader.officeHoursWeekendEnd.length === 8
          ? contractorsByHeader.officeHoursWeekendEnd
          : contractorsByHeader.officeHoursWeekendEnd + ':00',
      OOH_Dispatch_Number: !contractorsByHeader.ooH_Dispatch_Number ? null : contractorsByHeader.ooH_Dispatch_Number.trim(),
      OOH_Dispatch_Contact: !contractorsByHeader.ooH_Dispatch_Contact ? null : contractorsByHeader.ooH_Dispatch_Contact.trim(),
      ContactDetails: ContactDetails
    };

    postContractorContacts(payload as unknown as postContractorType).then(() => {
      scrollToTop();
    });
  }


  const emailBlur = (event: any) => {
    const validMail = event.target.value
    const validString = /^[a-zA-Z]+\.?[a-zA-Z]*\.?[0-9]*@sainsburys\.co\.uk$/i;
  }


  const phoneBlur = (event: any) => {
    const validPhone = event.target.value
  }

  const onChangeWeekdaysStart = (value: string) => {
    props.onData()
    setContractorsByHeader({ ...contractorsByHeader, officeHoursWeekdayStart: value })
    if (contractorsByHeader.officeHoursWeekdayEnd && value > contractorsByHeader.officeHoursWeekdayEnd) {
      SetWeekdaysStart(value > contractorsByHeader.officeHoursWeekdayEnd);
    } else {
      SetWeekdaysClose(false)
      SetWeekdaysStart(false)
    }
  }

  const onChangeWeekendsStart = (value: string) => {
    props.onData()
    setContractorsByHeader({ ...contractorsByHeader, officeHoursWeekendStart: value })
    if (contractorsByHeader.officeHoursWeekendEnd && (value > contractorsByHeader.officeHoursWeekendEnd)) {
      SetWeekendsStart(value > contractorsByHeader.officeHoursWeekendEnd);
    } else {
      SetWeekendsClose(false)
      SetWeekendsStart(false)
    }
  }

  const onChangeWeekdaysClose = (value: string) => {
    props.onData()
    setContractorsByHeader({ ...contractorsByHeader, officeHoursWeekdayEnd: value })
    if (contractorsByHeader.officeHoursWeekdayStart && (value < contractorsByHeader.officeHoursWeekdayStart)) {
      SetWeekdaysClose(value < contractorsByHeader.officeHoursWeekdayStart);
    } else {
      SetWeekdaysClose(false)
      SetWeekdaysStart(false)
    }
  }

  const onChangeWeekendsClose = (value: string) => {
    props.onData()
    setContractorsByHeader({ ...contractorsByHeader, officeHoursWeekendEnd: value })
    if (contractorsByHeader.officeHoursWeekendStart && (value < contractorsByHeader.officeHoursWeekendStart)) {
      SetWeekendsClose(value < contractorsByHeader.officeHoursWeekendStart);
    } else {
      SetWeekendsClose(false)
      SetWeekendsStart(false)
    }
  }

  const postContractorContacts = async (value: postContractorType) => {
    if (pathName === '/add-standard-contacts') {
      return contractorContactsApi
        .addContractorContacts(apiClient, value)
        .then(response => {
          if (response.ok) {
            console.log(response)
            props.onData(props.contractors.id ? props.contractors.id : props.contractors.companyId, 'discard')
            props.onData('Contractor Details added successfully.', 'SuccessAlert')

            return response
          } else {
            response.json().then(errorData => {
              console.log(errorData['BadRequest']);
              props.onData(errorData['BadRequest'], 'ErrorAlert');
            });
          }

        })
        .catch(error => props.onData(error, 'ErrorAlert'))
    } else {
      const payloadValue = {
        ...value,
        Id: props.contractors.id
      }
      console.log(payloadValue)
      return contractorContactsApi
        .editContractorContacts(apiClient, payloadValue)
        .then(response => {
          if (response.ok) {
            // console.log(response)
            props.onData(props.contractors.id ? props.contractors.id : props.contractors.companyId, 'discard')
            props.onData('Contractor Details updated successfully.', 'SuccessAlert')
            return response
          } else {
            response.json().then(errorData => {
              console.log(errorData['BadRequest']);
              props.onData(errorData['BadRequest'], 'ErrorAlert');
            });
          }

        })
        .catch(error => props.onData('Something went wrong!', 'ErrorAlert'))
    }
  }



  return (
    <>
      <ConfirmationModal
        showModal={modalState}
        onClose={() => setModalState(false)}
        onSave={handleSaveClick}
        onDiscard={handleDiscardForm}
      />
      <div style={{ background: 'white' }}>
        {pathName === '/add-standard-contacts' &&
          <div>
            <ContractorFormField label='Service'>
              <TextInputField
                onFocus={() => props.onData('clear')}
                name="text-input-field-2"
                onChange={(e: any) => onChangeContactDetails(e.target.value, 'contact')}
                value={contractorsByHeader.contract ? contractorsByHeader.contract : ''}
                onBlur={(e: any) => onBlurContactDetails(e.target.value, 'contact')}
              />
            </ContractorFormField>
          </div>
        }
        {pathName === '/add-standard-contacts' &&
          <div style={{ marginTop: '-10px' }}>
            <ContractorFormField label='Detail'>
              <TextInputField
                onFocus={() => props.onData('clear')}
                name="text-input-field-2"
                onChange={(e: any) => onChangeContactDetails(e.target.value, 'details')}
                value={contractorsByHeader.detail ? contractorsByHeader.detail : ''}
                onBlur={(e: any) => onBlurContactDetails(e.target.value, 'details')}
              />
            </ContractorFormField><br /><br /></div>
        }
        <ContractorFormField label='Office Hours *'>
          {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '-35%' }}>
            <h6><b>Office Hours </b><b style={{ color: 'red' }}>*</b></h6>
          </div> */}
          <div className='officeHours' id='officeHours'>
            <div></div>
            <div><b>Weekdays</b></div>
            <div><b>Weekends</b></div>

          </div><br />
          <div className='officeHours' >
            <div style={{ justifySelf: 'start' }}>Office Open</div>

            <div className='switchClass'>
              <div className='hideSpan'>No</div>
              <div className='switchCheckBox' >
                <Switch name="switch-with-text-ofcYes"
                  checked={contractorsByHeader.officeOpenWeekdays}
                  onChange={(e: any) => onChangeOfficeHours(e.target.checked, 'OPWeekdays')}
                />
              </div>
              <div className='hideSpan'>Yes</div>
            </div>
            <div className='switchClass'>
              <div className='hideSpan'>No</div>
              <div className='switchCheckBox'>
                <Switch name="switch-with-text-ofcNo"
                  checked={contractorsByHeader.officeOpenWeekends}
                  onChange={(e: any) => onChangeOfficeHours(e.target.checked, 'OPWeekends')}
                />
              </div>
              <div className='hideSpan'>Yes</div>
            </div>

          </div><br />
          <div className='officeHours' >
            <div style={{ justifySelf: 'start' }}>24 hours   </div>

            <div className='switchClass'>
              <div className='hideSpan'>No</div>
              <div className='switchCheckBox'>
                <Switch name="switch-with-texttt-24Yes"
                  onChange={(e: any) => onChangeOfficeHours(e.target.checked, 'THWeekdays')}
                  disabled={!contractorsByHeader.officeOpenWeekdays}
                  checked={contractorsByHeader.twentyFourHoursWeekdays}
                />
              </div>
              <div className='hideSpan'>Yes</div>
            </div>
            <div className='switchClass'>
              <div className='hideSpan'>No</div>
              <div className='switchCheckBox'>
                <Switch name="switch-with-text-24No"
                  onChange={(e: any) => onChangeOfficeHours(e.target.checked, 'THWeekends')}
                  disabled={!contractorsByHeader.officeOpenWeekends}
                  checked={contractorsByHeader.twentyFourHoursWeekends}
                />
              </div>
              <div className='hideSpan'>Yes</div>
            </div>

          </div><br />
          <div className='officeHours ofc_start'>
            <div style={{ justifySelf: 'start' }}> Start </div>
            <div style={{ justifyItems: 'center' }}>
              <input type="time" name="time" id="time"
                disabled={!contractorsByHeader.officeOpenWeekdays || contractorsByHeader.twentyFourHoursWeekdays}
                onChange={(e) => onChangeWeekdaysStart(e.target.value)}
                value={contractorsByHeader.officeHoursWeekdayStart}
                onFocus={() => props.onData('clear')}

              />
              {weekdaysStart &&
                <p className="ln-c-field-info ln-c-field-info--error" style={{ fontSize: 'small' }}>Start time must not exceed end time</p>
              }
            </div>
            <div style={{ justifyItems: 'center' }}>
              <input type="time" name="time" id="time"
                disabled={!contractorsByHeader.officeOpenWeekends || contractorsByHeader.twentyFourHoursWeekends}
                onChange={(e) => onChangeWeekendsStart(e.target.value)}
                onFocus={() => props.onData('clear')}
                value={contractorsByHeader.officeHoursWeekendStart}
              />
              {weekendsStart &&
                <p className="ln-c-field-info ln-c-field-info--error" style={{ fontSize: 'small' }}>Start time must not exceed end time</p>
              }
            </div>
          </div>
          <div className='officeHours ofc_end' >
            <div style={{ justifySelf: 'start' }}> End </div>

            <div style={{ justifyItems: 'center' }}>
              <input type="time" name="time" id="time"
                disabled={!contractorsByHeader.officeOpenWeekdays || contractorsByHeader.twentyFourHoursWeekdays}
                onChange={(e) => onChangeWeekdaysClose(e.target.value)}
                value={contractorsByHeader.officeHoursWeekdayEnd}
                onFocus={() => props.onData('clear')}
              />
              {weekdaysClose &&
                <p className="ln-c-field-info ln-c-field-info--error" style={{ fontSize: 'small' }}>End time must not be less than start time</p>
              }
            </div>
            <div style={{ justifyItems: 'center' }}>
              <input type="time" name="time" id="time"
                disabled={!contractorsByHeader.officeOpenWeekends || contractorsByHeader.twentyFourHoursWeekends}
                onChange={(e) => onChangeWeekendsClose(e.target.value)}
                onFocus={() => props.onData('clear')}
                value={contractorsByHeader.officeHoursWeekendEnd}
              />
              {weekendsClose &&
                <p className="ln-c-field-info ln-c-field-info--error" style={{ fontSize: 'small' }}>End time must not be less than Start time</p>
              }
            </div>

          </div><br />
        </ContractorFormField>
        <ContractorFormField label='Out of Hours'>
          {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '-35%' }}>
            <h6><b>Out of Hours</b></h6> <br />
          </div><br /> */}
          <div className='accessToVarisae'>
            <div>
              Access to Verisae
              </div>
            <div className='switchClass'>
              <div className='hideSwitch'>No</div>
              <div className='switchCheckBox'>
                <Switch name="switch-with-text-varisae"
                  onChange={(e: any) => {
                    setContractorsByHeader({ ...contractorsByHeader, verisaeOOHAccess: e.target.checked })
                  }}
                  onFocus={() => props.onData('clear')}
                  checked={contractorsByHeader.verisaeOOHAccess}
                />
              </div>
              <div className='hideSwitch'>Yes</div>
            </div>
            <div></div>
          </div>
          <div className='dispatchMethod'>
            <div style={{ justifySelf: 'start' }}>
              Dispatch Method
              </div>
            <div className="dispatch">
              <div className='dispatchCheckBox'>
                <CheckboxField
                  name="checkbox-field-1"
                  listType='inline'
                  options={[
                    { value: "Verisae", label: "Verisae", defaultChecked: contractorsByHeader.ooH_Dispatch_Verisae },
                    { value: "Email", label: "Email", defaultChecked: contractorsByHeader.ooH_Dispatch_Contact ? true : false },
                    { value: "Phone", label: "Phone", defaultChecked: contractorsByHeader.ooH_Dispatch_Number ? true : false },
                  ]}
                  onChange={(e: any) => onChangeDispatchBox(e.target)}
                />
              </div>
              <div style={{ width: '100%' }}>
                <div style={{ marginBottom: '-20px', marginTop: '3rem', width: '100%' }}>
                  <GridItem className='formGridAlign' size={{ xl: '1', ss: '1', default: '1' }}>
                    <TextInput
                      onFocus={() => props.onData('clear')}
                      id={'email'}
                      name='email'
                      required="*"
                      label="Username"
                      placeholder="email"
                      className='formInput'
                      disabled={!emailChecked}
                      onChange={(e: any) => onChangeDispatch(e.target.value, 'email')}
                      onBlur={(e: any) => emailBlur(e)}
                      value={contractorsByHeader.ooH_Dispatch_Contact}
                    />

                  </GridItem>
                  {invalidEmail &&
                    <p className="ln-c-field-info ln-c-field-info--error" style={errorStyle}>Invalid (eg : abc123@sainsburys.co.uk)</p>
                  }
                </div>
                <div>
                  <GridItem className='formGridAlign' size={{ xl: '1', ss: '1', default: '1' }}>
                    <TextInput
                      onFocus={() => props.onData('clear')}
                      id={'phone'}
                      name='phone'
                      placeholder="Phone"
                      className='formInput'
                      disabled={!phoneChecked}
                      onChange={(e: any) => onChangeDispatch(e.target.value, 'phone')}
                      onBlur={(e: any) => phoneBlur(e)}
                      value={contractorsByHeader.ooH_Dispatch_Number}
                    />
                  </GridItem>
                  {invalidPhone &&
                    <p className="ln-c-field-info ln-c-field-info--error" style={errorStyle}>Invalid Number ( eg : 99XXX XXXX99 )</p>
                  }
                </div>
              </div>
            </div>
          </div>

          <div className='notes'>
            <div style={{ alignContent: 'center' }}>
              Notes
            </div>
            <div className='textNotes'>
              <TextAreaField name="textarea-1"
                // label="Notes"
                onFocus={() => props.onData('clear')}
                onChange={(e: any) => {
                  setContractorsByHeader({ ...contractorsByHeader, notes: e.target.value })
                }}
                value={contractorsByHeader.notes ? contractorsByHeader.notes : ''}
              />
            </div>
            <div></div>
          </div><br />
        </ContractorFormField>




















        <Card>
          <GridWrapper verticalAlign="center">
            <GridItem>
              <div className='contacts_Contracts'>
                <h6><b>Contacts</b></h6>
              </div>
            </GridItem>
            {/* <div data-testid="accordion-multiple"> */}
            <div className='weekendHeadings'>
              <GridItem className='formGridAlign textAlignLast_Center left_contact_text' size={{ lg: '1/12', md: '2/12', default: '2/12' }}></GridItem>
              <GridItem className='formGridAlign textAlignLast_Center left_contact_text' size={{ lg: '1/5', md: '3/12', default: '3/12' }}><b>Name</b></GridItem>
              <GridItem className='formGridAlign textAlignLast_Center left_contact_text' size={{ lg: '1/8', md: '2/12', default: '2/12' }}><b>Phone</b></GridItem>
              <GridItem className='formGridAlign textAlignLast_Center left_contact_text' size={{ lg: '3/12', md: '3/12', default: '3/12' }}><b>Email</b></GridItem>
              <GridItem className='formGridAlign textAlignLast_Center left_contact_text' size={{ lg: '4/12', md: '2/12', default: '2/12' }}><b>Note</b></GridItem>
              {/* <GridItem className='formGridAlign textAlignLast_Center' size={{ lg: '2/12', md: '1', sm: '4/12', ss: '12/12', xs: '6/12', default: '1/1' }}></GridItem> */}
            </div>
            {options.map((option, optIndex) => (
              <div className='contractorContents'>
                <GridItem className='formGridAlign textAlignLast_Center left_contact_text' size={{ lg: '1/12', md: '2/12', sm: '2/12', ss: '2/12', default: '1/1' }}>
                  <div className='rowTextAlign '>
                    {option.name}
                    {(option.name === 'Director' || option.name === 'Primary') && (
                      <b style={{ color: 'red' }}> *</b>
                    )}
                  </div>
                </GridItem>
                <GridItem className='formGridAlign justifyItems ' size={{ lg: '1/5', md: '4/12', sm: '7/12', ss: '7/12', default: '1/1' }}>
                  <div className='dropDownAlign ' key={option.id}>
                    <AutocompleteField
                      key={optIndex}
                      placeholder={option.label ? option.label : 'Please select a Name'}
                      defaultValue={option.label ? option.label : ''}
                      name={"SiteSelector"}
                      options={users}
                      noResultsMessage="No stores found"
                      role='search'
                      onChange={(user: any) => handleChange(user, option.id, option.name)}
                      onSelect={(user: any) => handleSelect(user, option.id, option.name)}
                    />
                  </div>
                </GridItem>
                <GridItem className='formGridAlign textAlignLast_Center left_contact_text' size={{ lg: '1/8', md: '2/12', sm: '3/12', ss: '3/12', default: '1/1' }}>
                  <div className='rowTextAlign '>
                    <label>Phone </label>
                    <p>{selectedOption.find(opt => opt.level === option.name)?.phone}</p>
                  </div>
                </GridItem>
                <GridItem className='formGridAlign textAlignLast_Center left_contact_text' size={{ lg: '3/12', md: '4/12', sm: '5/12', ss: '5/12', default: '1/1' }}>
                  <div className='rowTextAlignNotes rowTextAlign ' data-fulltext={selectedOption.find(opt => opt.level === option.name)?.email}>
                    <label>Email </label>
                    <p>{selectedOption.find(opt => opt.level === option.name)?.email}</p>
                  </div>
                </GridItem>
                <GridItem className='formGridAlign' size={{ lg: '4/12', md: '6/12', sm: '7/12', ss: '7/12', default: '1/1' }}>
                  <div className='rowTextAlignNotes rowTextAlign' data-fulltext={selectedOption.find(opt => opt.level === option.name)?.notes}>
                    <label>Notes </label>
                    <p>{selectedOption.find(opt => opt.level === option.name)?.notes}</p>
                  </div>
                </GridItem>

              </div>
            ))}
          </GridWrapper>
        </Card>







































        {/* <div className='contactsGridItem'>
          <GridItem>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h6><b>Contacts</b></h6> <br /><br /><br />
            </div>
          </GridItem>
          <div>
            <GridItem size={{ xl: '1/12', md: '1/12', sm: '1/1', xs: '1/1', default: '1/1' }}></GridItem>
            <GridItem size={{ xl: '1/5', md: '1/5', sm: '5/12', default: '1/1' }}>Name</GridItem>
            <GridItem size={{ xl: '1/5', md: '1/5', sm: '5/12', default: '1/1' }}>Email</GridItem>
            <GridItem size={{ xl: '1/8', md: '1/8', sm: '2/12', default: '1/1' }}>Phone</GridItem>
            <GridItem size={{ xl: '4/12', md: '4/12', sm: '1/1', default: '1/1' }}>Notes</GridItem>
          </div><br />
          {options.map((option, optIndex) => (
            <div className='xContacts'>
              <GridItem size={{ xl: '1/12', md: '1/12', sm: '1/1', xs: '1/1', default: '1111' }}>{option.name}
                {(option.name === 'Director' || option.name === 'Primary') && (
                  <b style={{ color: 'red' }}> *</b>
                )}</GridItem>
              <GridItem size={{ xl: '1/5', md: '1/5', sm: '5/12', xs: '6/12', default: '1/1' }}>
                <AutocompleteField
                  className='contactsName'
                  key={optIndex}
                  placeholder={option.label ? option.label : 'Please select a Name'}
                  value={option.label}
                  name={"SiteSelector"}
                  options={users}
                  noResultsMessage="No stores found"
                  role='search'
                  onChange={(user: any) => handleChange(user, option.id, option.name)}
                  onSelect={(user: any) => handleSelect(user, option.id, option.name)}
                />
              </GridItem>
              <GridItem className='alignEmailPhn' size={{ xl: '1/5', md: '1/5', sm: '5/12', xs: '6/12', default: '1/1' }}>
                {selectedOption.find(opt => opt.level === option.name)?.email}
              </GridItem>
              <GridItem className='alignEmailPhn' size={{ xl: '1/8', md: '1/8', sm: '2/12', xs: '4/12', default: '1/1' }}>
                {selectedOption.find(opt => opt.level === option.name)?.phone}
              </GridItem>
              <GridItem size={{ xl: '4/12', md: '4/12', sm: '1/1', xs: '8/12', default: '1/1' }}>
                {'selectedOption.find(opt => opt.level === option.name)?.notes'}
              </GridItem>
            </div>
          ))}
        </div> */}








        <GridItem>
          {/* <table className="workorderdetails">
            <tbody >
              <tr>
                <th></th>
                <th className='wrkName'>Name</th>
                <th className='wrkEmail'>Email</th>
                <th className='wrkPhone'>Phone</th>
                <th className='wrkNote'>Phone</th>
              </tr>
              {options.map((option, optIndex) => (
                <tr>
                  <td>
                    {option.name}
                    {(option.name === 'Director' || option.name === 'Primary') && (
                      <b style={{ color: 'red' }}> *</b>
                    )}
                  </td>
                  <td >
                    <div style={{ marginTop: '10px', marginBottom: '-10px' }} key={optIndex}>
                      <AutocompleteField
                        key={optIndex}
                        placeholder={option.label ? option.label : 'Please select a Name'}
                        value={option.label}
                        name={"SiteSelector"}
                        options={users}
                        noResultsMessage="No stores found"
                        role='search'
                        onChange={(user: any) => handleChange(user, option.id, option.name)}
                        onSelect={(user: any) => handleSelect(user, option.id, option.name)}
                      />
                    </div>
                  </td>
                  {selectedOption.some(opt => opt.level === option.name) && (
                    <>
                      <td>
                        {selectedOption.find(opt => opt.level === option.name)?.email}
                      </td>
                      <td>
                        {selectedOption.find(opt => opt.level === option.name)?.phone}
                      </td>
                      <td>
                        {selectedOption.find(opt => opt.level === option.name)?.notes}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table> */}
        </GridItem>

        <hr />
        <>
          <GridItem size={{ xl: '1/4', ss: '1/4', default: '0' }}><></></GridItem>
          <GridItem size={{ xl: '3/4', ss: '3/4', default: '1/1' }} className='formInputMaxWidth'>
            <div className='formButtonGroup'
            >
              <OutlinedButton size='60px' onClick={() => { setModalState('discard') }} onScroll={handleScrollModal} className='formDiscardButton' >Discard</OutlinedButton>
              <FilledButton size='60px' onClick={() => { handleDataOnSaveClick() }} onScroll={handleScrollModal} className='formSaveButton' >Save</FilledButton>
              {/* <FilledButton size='60px' onClick={() => testApi()} onScroll={handleScrollModal} className='formSaveButton' >test Save</FilledButton> */}
            </div>
          </GridItem>
        </>
        <br /><br /><br />
      </div>

    </>
  )
}

export default AddContractors