import React, { useState, useEffect, useRef } from "react";
import { TextInput, TextInputField } from "@jsluna/form";
import { SelectField, Input, AutocompleteField } from "@jsluna/react";
import { Card } from "@jsluna/card";
import { GridWrapper, GridItem } from "@jsluna/grid";
import { Accordion } from "@jsluna/accordion";
import { Button, OutlinedButton, FilledButton } from "@jsluna/button";
import {
    SCMWeekendUsersList,
    SCMUsersListCollection,
    postSCMWeekendUsersList,
} from "../../../types/Entities/WeekendOnCallType";
import { SCMDetails } from "../../../types/Entities/SiteCareManager";
import ConfirmationModal from "../../Common/ContractorForm/ConfirmationModal";
import FormAlerts from "../../Common/ContractorForm/FormAlerts";
import siteCareManagerAPi from "../../../api/siteCareManagerApi";
import { useApiClient } from "../../../context/AppContext";
import siteCareManagerApi from "../../../api/siteCareManagerApi";
import weekendOnCallApi from "../../../api/weekendOnCallApi";
import { useMemo } from "react";
import moment from "moment";
import CSS from "csstype";
// import
// type SCMWeekendProps = {
//   onData: any;
// };

const Weekends = () => {
    const errorStyle: CSS.Properties = {
        textAlign: "left",
        fontSize: "small",
        margin: 0,
    };

    const [onSave, setOnSave] = useState(false)
    const [details, setDetails] = useState<SCMWeekendUsersList[]>([]);
    const [selectedDetail, setSelectedDetail] =
        useState<SCMWeekendUsersList | null>(null);
    const [showAccordion, setShowAccordion] = useState(true);
    const [accordionExpand, setAccordionExpand] = useState(true);
    const [errorAlert, setErrorAlert] = useState<false | string>(false);
    const [successAlert, setSuccessAlert] = useState<false | string>(false);
    const [modalState, setModalState] = useState<
        "savescm" | "discard" | "saveusers" | false
    >(false);
    const [scmdetails, setscmdetails] = useState([] as SCMDetails[]);
    const [weekendDetails, setweekenddetails] = useState(
        [] as SCMWeekendUsersList[]
    );
    const [startDateTime, setStartDateTime] = useState<string>();
    const [endDateTime, setEndDateTime] = useState<string>();
    const [detailValue, setDetailValue] = useState<string>();
    const [currentDate, setCurrentDate] = useState("");
    // const[validationMessage,setValidationMessage]=useState<false| true |string>(false);
    const [detailvalidationMessages, setDetailValidationMessages] = useState<{
        [key: string]: boolean;
    }>({});
    const [notesvalidationMessages, setNotesValidationMessages] = useState<{
        [key: string]: boolean;
    }>({});
    const [startDateValidation, setStartDateValidation] = useState<{
        [key: string]: boolean;
    }>({});
    const [endDateValidation, setEndDateValidation] = useState<{
        [key: string]: boolean;
    }>({});
    const [nameValidation, setNameValidation] = useState<{
        [key: string]: boolean;
    }>({});
    const [latestStartDate, setLatestStartDate] = useState(false)
    const [latestEndDate, setLatestEndDate] = useState(false)

    //The key is the rowId (a unique identifier for each row), and the value is a boolean (true for invalid and false for valid).

    const apiClient = useApiClient();

    useEffect(() => {
        loadscmdetails();
        loadDetails();
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const day = String(now.getDate()).padStart(2, "0");
        const todayDate = `${year}-${month}-${day}`;
        setCurrentDate(todayDate);

    }, [onSave]);
    const [weekendsRow, setWeekendsRows] = useState<SCMWeekendUsersList[]>([{
        id: crypto.randomUUID().toString(),
        rowId: crypto.randomUUID().toString(),
        scmUsersId: crypto.randomUUID().toString(),
        startDate: "",
        endDate: "",
        email: "",
        phone: "",
        isNew: 1,
        notes: "",
        detail: "",
        name: "",
    }]);

    const loadscmdusers = () => {
        const y = loadscmdetails();

    };
    const loadDetails = () => {
        const x = loadWeekendDetails();

    };
    const loadscmdetails = () => {
        return siteCareManagerAPi
            .getSCMUsersDetails(apiClient)
            .then((response) => {
                const x = response.map((item) => ({
                    id: item.id,
                    role: item.role,
                    name: item.name,
                    email: item.email,
                    phone: item.phone,
                    isNew: item.isNew,
                    label: item.name,
                    value: item.name,
                }));
                setscmdetails(x);
                // clearAlerts();
            })
            .catch((error) => {
                setErrorAlert("Something went wrong!");
                setSuccessAlert(false)
                const event = new Event("click", { bubbles: true });
                document
                    .querySelector(
                        "button.ln-c-button.ln-c-input-group__action, button[aria-label='close menu']"
                    )
                    ?.dispatchEvent(event);
                return Promise.resolve([]);
            });
    };

    const loadWeekendDetails = () => {
        return weekendOnCallApi
            .getSCMWeekendUserDetails(apiClient)
            .then((response) => {
                const x = response.map((item) => ({
                    name: item.name,
                    id: item.id,
                    detail: item.detail,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    scmUsersId: item.scmUsersId,
                    notes: item.notes,
                    isNew: item.isNew,
                    email: item.email,
                    phone: item.phone,
                    rowId: crypto.randomUUID().toString(),
                    invalidStartDate: false,
                    invalidEndDate: false
                }));
                setWeekendsRows(x);
                // console.log("notes")
                setAccordionExpand(true);
                // clearAlerts();
            })

            .catch((error) => {
                setErrorAlert("Something went wrong");
                setSuccessAlert(false);
                return Promise.resolve([]);
            });
    };
    const handleSaveForm = () => {
        setModalState(false);
        const payload = [...weekendsRow].map((x: any) =>
            x.isNew === 1
                ? {
                    SCMUsersId: x.scmUsersId,
                    Notes: x.notes,
                    StartDate: x.startDate.length !== 16 ? x.startDate : x.startDate + ":00",
                    EndDate: x.endDate.length !== 16 ? x.endDate : x.endDate + ":00",
                    Detail: x.detail,
                    IsNew: x.isNew,
                }
                : {
                    Id: x.id,
                    SCMUsersId: x.scmUsersId,
                    Notes: x.notes,
                    StartDate: x.startDate.length !== 16 ? x.startDate : x.startDate + ":00",
                    EndDate: x.endDate.length !== 16 ? x.endDate : x.endDate + ":00",
                    Detail: x.detail,
                    IsNew: x.isNew,
                }
        ) as unknown as postSCMWeekendUsersList[];
        weekendOnCallApi
            .addorupdatesitecareoncall(apiClient, payload)
            .then((res) => {
                scrollToTop();
                clearAlerts();
                if (res.ok) {
                    setSuccessAlert("Updated Successfully.");
                    setErrorAlert(false);
                    setOnSave(!onSave)
                } else {
                    const errorMessage = res.json()
                    setErrorAlert("Something went wrong!");
                    setSuccessAlert(false)
                }
            })
            .catch((err) => {
                scrollToTop();
                setErrorAlert("Something went wrong!");
                setSuccessAlert(false)
            });
    };
    const addRowToGrid = () => {
        let newguid = crypto.randomUUID();
        setWeekendsRows([
            ...weekendsRow,
            {
                id: newguid.toString(),
                rowId: newguid.toString(),
                scmUsersId: newguid.toString(),
                startDate: "",
                endDate: "",
                email: "",
                phone: "",
                isNew: 1,
                notes: "",
                detail: "",
                name: "",
            },
        ]);
    };
    const handleChange = (
        option: { target: { value: string } },
        id: string,
        rowId: string
    ) => {
        // const findIndex = [...weekendsRow].findIndex(x => x.rowId == rowId)
        const fIndex = [...scmdetails].filter(
            (x: any) => x.name === option.target.value
        );
        if (fIndex.length > 0) {
            let WONCall = [...weekendsRow].map((x: any) =>
                x.rowId === rowId
                    ? {
                        ...x,
                        name: fIndex[0]["name"],
                        // email: fIndex[0]["email"],
                        // phone: fIndex[0]["phone"],
                        scmUsersId: fIndex[0]["id"],
                    }
                    : x
            );
            setWeekendsRows(WONCall);
        } else {
            let WONCall = [...weekendsRow].map((x: any) =>
                x.rowId === rowId
                    ? {
                        ...x,
                        name: '',
                        email: '',
                        phone: '',
                        scmUsersId: '',
                    }
                    : x
            );
            setWeekendsRows(WONCall);

        }
    };

    const handleOptionChange = (
        option: {
            id?: string;
            name?: string;
            email?: string;
            phone?: string;
            detail?: string;
            notes?: string;
            StartDate?: string;
            EndDate?: string;
        },
        id: string,
        rowId: string
    ) => {
        const updatedRows = weekendsRow.map((row) =>
            row.rowId === rowId
                ? {
                    ...row,
                    name: option.name || "",
                    email: option.email || "",
                    phone: option.phone || "",
                    detail: option.detail || "",
                    notes: option.notes || "",
                    StartDate: option.StartDate || "",
                    EndDate: option.EndDate || "",
                }
                : row
        );

        setWeekendsRows(updatedRows);
    };

    const truncateText = (text: string, maxLength: number) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        }
        return text;
    };

    const onChangeNames = (value: string, rowIndex: string) => {
        const wONCall = weekendsRow.map((x) =>
            x.rowId === rowIndex ? { ...x, name: value } : x
        );

        setWeekendsRows(wONCall);
    };
    const detailBlur = (
        event: any,
        indx: number,
        type: string,
        rowId: string
    ) => {
        const scmlist1 = [...weekendsRow];
        let itemindex1 = scmlist1.findIndex((i) => i.rowId === rowId);

        // Check if the 'detail' field is empty and update the validation state for that row
        if (scmlist1[itemindex1]["detail"] === "") {
            setDetailValidationMessages((prevState) => ({
                ...prevState,
                [rowId]: true, // Set validation to true for the specific row
            }));
        } else {
            setDetailValidationMessages((prevState) => ({
                ...prevState,
                [rowId]: false, // Set validation to false for the specific row
            }));
        }
    };
    const notesBlur = (event: any, indx: number, type: string, rowId: string) => {
        const scmlist2 = [...weekendsRow];
        let itemindex2 = scmlist2.findIndex((i) => i.rowId === rowId);
        if (scmlist2[itemindex2]["notes"] === "") {
            setNotesValidationMessages((prevState) => ({
                ...prevState,
                [rowId]: true, // Set validation to true for the specific row
            }));
        } else {
            setNotesValidationMessages((prevState) => ({
                ...prevState,
                [rowId]: false, // Set validation to false for the specific row
            }));
        }
    };
    const startDateBlur = (
        event: any,
        indx: number,
        type: string,
        rowId: string
    ) => {
        const scmlist3 = [...weekendsRow];
        let itemindex3 = scmlist3.findIndex((i) => i.rowId === rowId);
        if (scmlist3[itemindex3]["startDate"] === "") {
            setStartDateValidation((prevState) => ({
                ...prevState,
                [rowId]: true, // Set validation to true for the specific row
            }));
        } else {
            setStartDateValidation((prevState) => ({
                ...prevState,
                [rowId]: false, // Set validation to false for the specific row
            }));
        }
    };
    const endDateBlur = (
        event: any,
        indx: number,
        type: string,
        rowId: string
    ) => {
        const scmlist4 = [...weekendsRow];
        let itemindex4 = scmlist4.findIndex((i) => i.rowId === rowId);
        if (scmlist4[itemindex4]["endDate"] === "") {
            setEndDateValidation((prevState) => ({
                ...prevState,
                [rowId]: true, // Set validation to true for the specific row
            }));
        } else {
            setEndDateValidation((prevState) => ({
                ...prevState,
                [rowId]: false, // Set validation to false for the specific row
            }));
        }
    };
    const nameBlur = (event: any, indx: number, type: string, rowId: string) => {
        const scmlist4 = [...weekendsRow];
        let itemindex4 = scmlist4.findIndex((i) => i.rowId === rowId);
        if (scmlist4[itemindex4]["name"] === "") {
            setNameValidation((prevState) => ({
                ...prevState,
                [rowId]: true, // Set validation to true for the specific row
            }));
        } else {
            setNameValidation((prevState) => ({
                ...prevState,
                [rowId]: false, // Set validation to false for the specific row
            }));
        }
    };

    const scrollToTop = () =>
        document.querySelector("div.ln-o-container.content")?.scrollTo(0, 0);

    const removeRowFromGrid = (
        event: Event | React.MouseEvent<HTMLButtonElement>,
        rowIndex: string
    ) => {
        let fmlist = [...weekendsRow];
        if (fmlist.length === 1) {
            return;
        }
        var foundIndex = fmlist.findIndex((x) => x.rowId == rowIndex);
        // if (fmlist[foundIndex]['isNew'] === 1) {
        //     const wONCall = fmlist.filter(x => x.rowId !== rowIndex)
        //     setWeekendsRows(wONCall);
        //     return
        // }

        if (fmlist[foundIndex].name !== "") {
            setErrorAlert(
                `You have removed ${fmlist[foundIndex].name}. To save the changes, please click on the Save button.`
            );
            setSuccessAlert(false)
            scrollToTop();
        }
        if (fmlist[foundIndex]["isNew"] === 1) {
            fmlist.splice(foundIndex, 1);
        } else {
            fmlist[foundIndex]["isNew"] = 0;
        }
        setWeekendsRows(fmlist);
        // setSuccessAlert(false)
    };
    const handleScrollModal = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "auto",
        });
    };

    const clearAlerts = () => {
        setErrorAlert(false);
        setSuccessAlert(false);
    };
    const validateConditions = (x: SCMWeekendUsersList) => {
        const conditions = [x.detail, x.name, x.startDate, x.endDate];
        const trueCount = conditions.filter(Boolean).length;
        const falseCount = conditions.length - trueCount;

        return falseCount >= 1 && trueCount <= 3;
    }

    const handleSaveButtonClick = () => {
        setSuccessAlert(false);
        let validateSCM = [...weekendsRow]


        const scm = validateSCM.map(x => (
            validateConditions(x) ? { ...x, error: true } : { ...x, error: false }
        ));
        setWeekendsRows(scm)
        for (let i = 0; i < scm.length; i++) {
            if (scm[i].invalidStartDate && scm[i].invalidEndDate) {
                const invalid_start_Date = scm[i].invalidStartDate
                const invalid_end_Date = scm[i].invalidEndDate
                if (scm[i].error || invalid_start_Date || invalid_end_Date) {
                    setErrorAlert('Some fields are Invalid please Verify!')
                    setSuccessAlert(false)
                    return
                }
            }
        }
        setModalState("savescm");

        // setErrorAlert('Some fields are Invalid please Verify!')

    };
    const handleDiscardForm = () => {
        setModalState(false);
        setAccordionExpand(false);
        clearAlerts();
        loadscmdetails();
        loadDetails();
    };

    const handleDetailInput = (value: string, rowIndex: string) => {
        const scmlist = [...weekendsRow];
        let itemindex = scmlist.findIndex((i) => i.rowId === rowIndex);
        // const pattern = /^[A-Za-z0-9 ]*$/;
        // if (pattern.test(value)) {
            scmlist[itemindex]["detail"] = value;
        // }

        setWeekendsRows(scmlist);
    };
    const handleNotes = (value: string, rowIndex: string) => {
        const scmlist = [...weekendsRow];
        let itemindex = scmlist.findIndex((i) => i.rowId === rowIndex);
        // const pattern = /^[A-Za-z0-9 ]*$/;
        // if (pattern.test(value)) {
            scmlist[itemindex]["notes"] = value;
        // }

        setWeekendsRows(scmlist);

    }

    const handleDate = (value: string, rowIndex: string, type: string) => {
        let scmUsersList = [...weekendsRow];
        const findIndex = scmUsersList.findIndex((x) => x.rowId === rowIndex);
        switch (type) {
            case "startDate":
                debugger
                scmUsersList[findIndex]["startDate"] = value;
                const endDateValue = scmUsersList[findIndex]["endDate"]
                if (value && endDateValue) {
                    if (new Date(value).getTime() > new Date(endDateValue).getTime()) {
                        scmUsersList[findIndex]["invalidStartDate"] = true;
                    }
                    else {
                        scmUsersList[findIndex]["invalidStartDate"] = false;
                        scmUsersList[findIndex]["invalidEndDate"] = false;
                    }
                }
                break;
            case "endDate":
                scmUsersList[findIndex]["endDate"] = value;
                const startDateValue = scmUsersList[findIndex]["startDate"]
                if (value && startDateValue) {
                    if (new Date(value).getTime() < new Date(startDateValue).getTime()) {
                        scmUsersList[findIndex]["invalidEndDate"] = true;
                    }
                    else {
                        scmUsersList[findIndex]["invalidEndDate"] = false;
                        scmUsersList[findIndex]["invalidStartDate"] = false;
                    }
                }
                break;
        }
        console.log(scmUsersList[findIndex], new Date(value).getTime(), new Date(scmUsersList[findIndex]["endDate"]!).getTime())
        setWeekendsRows(scmUsersList);
    };
    return (
        <>
            <ConfirmationModal
                showModal={modalState}
                onClose={() => setModalState(false)}
                onSave={handleSaveForm}
                onDiscard={handleDiscardForm}
            />
            <FormAlerts
                showError={errorAlert}
                showSuccess={successAlert}
                onClose={clearAlerts}
            />
            <Card className="contractorForm">
                {/* <h5>Add/Update Site Care User Details</h5> */}
                <GridWrapper verticalAlign="center">
                    <h4 className='site_care_on_call'>Site Care On-Call</h4>
                    <div className='scm_Weekend_on_call_headings'>
                        <GridItem className='formGridAlign textAlignLast_Center' size={{ lg: '2/12', md: '2/12', xs: '4/12', default: '1/1' }}><b>Detail</b> <b style={{ color: 'red' }}>*</b></GridItem>
                        <GridItem className='formGridAlign textAlignLast_Center' size={{ lg: '2/12', md: '2/12', xs: '4/12', default: '1/1' }}><b>Name</b><b style={{ color: 'red' }}>*</b></GridItem>
                        <GridItem className='formGridAlign textAlignLast_Center' size={{ lg: '2/12', md: '2/12', xs: '4/12', default: '1/1' }}><b>Start Date<b style={{ color: 'red' }}>*</b></b></GridItem>
                        <GridItem className='formGridAlign textAlignLast_Center' size={{ lg: '2/12', md: '2/12', xs: '4/12', default: '1/1' }}><b>End Date<b style={{ color: 'red' }}>*</b></b></GridItem>
                        <GridItem className='formGridAlign textAlignLast_Center' size={{ lg: '2/12', md: '2/12', xs: '4/12', default: '1/1' }}><b>Notes</b></GridItem>
                        <GridItem className='formGridAlign textAlignLast_Center' size={{ lg: '2/12', md: '2/12', xs: '4/12', default: '1/1' }}><b></b></GridItem>
                    </div><br />
                    {/* <GridItem className='formGridAlign textAlignLast_Center' size={{ lg: '1/6' }}></GridItem> */}
                    {/* <GridItem className='formGridAlign textAlignLast_Center' size={{ lg: '2/12', md:'2/12', xs: '4/12', '1/1' }}></GridItem> */}
                    {weekendsRow.filter((row: any) => row.isNew !== 0).map((row: any, index: number) => (
                        <div className='scm_Weekend_on_call'>
                            <GridItem className='formGridAlign scm_detail' size={{ lg: '2/12', md: '2/12', xs: '4/12', default: '1/1' }}>
                                <label>Detail <b style={{ color: 'red' }}>*</b></label>
                                <TextInputField
                                    name="text-input-field-1"
                                    value={row.detail}
                                    style={{ padding: '5% 8%' }}
                                    onChange={(e: any) => {
                                        handleDetailInput(e.target.value, row.rowId);
                                    }}
                                    onBlur={(e: any) =>
                                        detailBlur(e, index, "hfml", row.rowId)
                                    }
                                />
                                {
                                    row.error && row.detail === "" && (row.name || row.startDate || row.endDate) && (
                                        <p
                                            className="ln-c-field-info ln-c-field-info--error"
                                            style={errorStyle}
                                        >
                                            Detail can't be blank (e.g. cleaning)
                                        </p>

                                    )}
                            </GridItem>
                            <GridItem className='formGridAlign' size={{ lg: '2/12', md: '2/12', xs: '4/12', default: '1/1' }}>
                                <label>Name <b style={{ color: 'red' }}>*</b></label>
                                <SelectField
                                    value={row.name}
                                    options={scmdetails}
                                    style={{ padding: '5% 8%' }}
                                    onChange={(selectedOption: any) => handleChange( selectedOption, row.id, row.rowId )}
                                    onBlur={(e: any) => nameBlur(e, index, "hfml", row.rowId) }
                                />
                                {row.error && row.name === "" && (row.detail || row.startDate || row.endDate) && (
                                    <p
                                        className="ln-c-field-info ln-c-field-info--error"
                                        style={errorStyle}
                                    >
                                        Please select a name from dropdown
                                    </p>
                                )}
                            </GridItem>
                            <GridItem className='formGridAlign' size={{ lg: '2/12', md: '2/12', xs: '4/12', default: '1/1' }}>
                                <label>Start Date & Time <b style={{ color: 'red' }}>*</b></label>
                                <Input
                                    type="datetime-local" name="startDateTime" id="startDateTime"
                                    style={{ padding: '5% 2%', width: '100%' }}
                                    value={row.startDate.length == 19 ? row.startDate.slice(0, -3) : row.startDate}
                                    min={currentDate + "T00:00"}
                                    disabled={new Date(row.startDate).getTime() < new Date(currentDate).getTime()}
                                    onChange={(e: any) => handleDate(e.target.value, row.rowId, "startDate")}
                                    onBlur={(e: any) => startDateBlur(e, index, "hfml", row.rowId)} 
                                    onKeyDown={(e: any) => e.preventDefault()}/>
                                {row.error && row.startDate === "" && (row.detail || row.name || row.endDate) && (
                                    <p className="ln-c-field-info ln-c-field-info--error" style={errorStyle}>
                                        Please Select a valid Start Date
                                    </p>
                                )}
                                {/* {new Date(row.startDate).getTime() > new Date(row.endDate).getTime() && ( */}
                                {row.invalidStartDate && (
                                    <p className="ln-c-field-info ln-c-field-info--error" style={errorStyle}>
                                        Invalid Start Date
                                    </p>
                                )}
                            </GridItem>
                            <GridItem className='formGridAlign' size={{ lg: '2/12', md: '2/12', xs: '4/12', default: '1/1' }}>
                                <label>End Date & Time <b style={{ color: 'red' }}>*</b></label>
                                <Input
                                    label='xxx'
                                    type="datetime-local" name="endDateTime" id="endDateTime"
                                    style={{ padding: '5% 2%', width: '100%' }}
                                    value={row.endDate.length == 19 ? row.endDate.slice(0, -3) : row.endDate} min={currentDate + "T00:00"}
                                    onChange={(e: any) => handleDate(e.target.value, row.rowId, "endDate")}
                                    onBlur={(e: any) => endDateBlur(e, index, "hfml", row.rowId)} 
                                     onKeyDown={(e: any) => e.preventDefault()}
                                    />
                                {row.error && row.endDate === "" && (row.detail || row.name || row.startDate) && (
                                    <p
                                        className="ln-c-field-info ln-c-field-info--error"
                                        style={errorStyle}
                                    >
                                        Please Select a valid End Date
                                    </p>
                                )}
                                {/* {new Date(row.startDate).getTime() > new Date(row.endDate).getTime() && ( */}
                                {row.invalidEndDate && (
                                    <p className="ln-c-field-info ln-c-field-info--error" style={errorStyle}>
                                        Invalid End Date
                                    </p>
                                )}
                            </GridItem>
                            <GridItem className='formGridAlign ' size={{ lg: '2/12', md: '2/12', xs: '4/12', default: '1/1' }}>
                                <label>Notes <b style={{ color: 'red' }}>*</b></label>
                                <TextInputField
                                    name="text-input-field-2"
                                    value={row.notes}
                                    onChange={(e: any) => handleNotes(e.target.value, row.rowId)}
                                    style={{ padding: '5% 8%' }}
                                // onBlur={(e: any) =>
                                //   notesBlur(e, index, "hfml", row.rowId)
                                // }
                                />
                            </GridItem>
                            <GridItem className='formGridAlign textAlignLast_Center' size={{ lg: '2/12', md: '2/12', xs: '4/12', sdefault: '1/1' }}>
                                <div className="formButtonGroup">
                                    {weekendsRow.filter((x: any) => x.isNew != 0).length > 1 && (
                                        <OutlinedButton
                                            id='removeButton'
                                            fullWidth
                                            onClick={(e: any) => removeRowFromGrid(e, row.rowId)}
                                        >Remove</OutlinedButton>
                                    )}
                                    {weekendsRow.filter((row: any) => row.isNew !== 0).length == index + 1 && (
                                        <FilledButton
                                            id='addButton'
                                            fullWidth
                                            onClick={addRowToGrid}
                                            style={{ paddingLeft: '5px' }}
                                        >Add</FilledButton>
                                    )}
                                </div>
                            </GridItem>
                        </div>
                    ))}
                </GridWrapper>
                <>
                    <GridItem size={{ xl: "1/4", ss: "1/3", default: "0" }}>
                        <></>
                    </GridItem>
                    <GridItem
                        size={{ xl: "1/3", ss: "2/3", default: "1/1" }}
                        className="formInputMaxWidth"
                    >
                        <div className="formButtonGroup">
                            <OutlinedButton
                                style={{ width: "100%" }}
                                onClick={() => {
                                    setModalState("discard");
                                }}
                                className="formDiscardButton"
                            >
                                Discard
              </OutlinedButton>
                            <FilledButton
                                style={{ width: "100%" }}
                                // onClick={() => setModalState('savescm')}
                                className="formSaveButton"
                                onClick={handleSaveButtonClick}
                            >
                                Save
              </FilledButton>
                        </div>
                    </GridItem>
                </>
            </Card>
        </>
    );
};

export default Weekends;