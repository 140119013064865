import React, { useEffect, useRef, useState } from "react";
import { Accordion } from "@jsluna/accordion";
import { AsyncAutocompleteField } from "@jsluna/react";
import ContractorFormField from "../../Common/ContractorForm/ContractorFormField";
import { Search } from "@jsluna/icons";
import contractorContactsApi from "../../../api/contractorContactsApi";
import { ContractorSearchItem } from "../../../types/Entities/ContractorEscalation";
import { ContractorManagementType } from "../../../types/Entities/ContractorManagementType";
import { useApiClient } from "../../../context/AppContext";
import { GridWrapper, GridItem } from "@jsluna/grid";
import {
  FilledButton,
  OutlinedButton,
  TextInputField,
  TextInput,
} from "@jsluna/react";
import CSS from "csstype";
import { Card } from "@jsluna/card";
import ConfirmationModal from "../../Common/ContractorForm/ConfirmationModal";
import FormAlerts from "../../Common/ContractorForm/FormAlerts";
import ContractorForm from "../../Common/ContractorForm";
import { contractorContactsusers } from "../../../types/Entities/NewContractorType";

const Users = () => {
  const errorStyle: CSS.Properties = {
    textAlign: "left",
    fontSize: "small",
    margin: 0,
  };
  const [accordionexpand, setaccordionexpand] = useState<boolean>(false);
  // const [selectedContractor, setSelectedContractor] = useState({
  //   id: "",
  //   contractorName: "",
  // } as ContractorManagementType);
  const [errorAlert, setErrorAlert] = useState<false | string>(false);
  const [successAlert, setSuccessAlert] = useState<false | string>(false);
  const [tempArrayforDuplicateName, setTempArrayforDuplicateName] = useState<
    string[]
  >([]);
  const [nameIndex, setNameIndex] = useState(0);
  const [invalidNameIndex, setInvalidNameIndex] = useState(0);
  const [duplicatename, setDuplicatename] = useState(false);
  const [tempArrayforInvalidMail, setTempArrayforInvalidMail] = useState<
    string[]
  >([]);
  const [tempArrayforInvalidNumbers, setTempArrayforInvalidNumbers] = useState<
    string[]
  >([]);
  const [validFMNumber, setValidFMNumber] = useState(false);
  const [phnIndex, setPhnIndx] = useState(0);
  const [invalidName, setInvalidName] = useState(false);
  const [validFMEmailAddress, setValidFMEmailAddress] = useState(false); //facility manger
  const [modalState, setModalState] = useState<
    "savescm" | "discard" | "saveusers" | false
  >(false);
  const [indx, setIndx] = useState(0);
  const [showaccordion, setShowAccordion] = useState<boolean>(false);

  const [userdetails, setuserdetails] = useState([{}] as contractorContactsusers[]);
  const [dummyUserDetails, setDummyUserdetails] = useState([{}] as contractorContactsusers[]);
  // const [autocompleteKey, setAutocompleteKey] = useState(0);
  const [companyId, setCompanyId] = useState<string>("");
  const [disableDiscardSaveButton, setDisableDiscardSaveButton] = useState(true)

  const myFormRef: any = useRef();
  const apiClient = useApiClient();
  const scrollToTop = () => document.querySelector("div.ln-o-container.content")?.scrollTo(0, 0);
  const [loadPage, setLoadPage] = useState(false)

  useEffect(() => {
    if (companyId) {
      loaduserdetails(companyId);
      // clearAlerts(); // Assuming clearAlerts is defined elsewhere
    }
  }, [loadPage]);

  // const loadusers =  (CompanyId: string) => {
  //   return loaduserdetails(CompanyId)
  // };

  const loaduserdetails = (CompanyId: string) => {
    return contractorContactsApi.getUserDetails(apiClient, CompanyId)
      .then((response) => {
        console.log(response)
        const usersList = response.map(res => ({
          ...res,
          isNew: null
        }))
        setuserdetails(usersList as unknown as contractorContactsusers[]);
        setDummyUserdetails({ ...usersList } as unknown as contractorContactsusers[])
        return response
      })
  }

  const loadContractorSearch = (
    value: string
  ): Promise<ContractorSearchItem[]> => {
    console.log("Search value:", value);
    return contractorContactsApi
      .findContractorContactsCollection(apiClient, value)
      .then((response) => {
        console.log("API response:", response);
        const mappedResponse = response.map((item) => ({
          label: item.label,
          value: item.value,
        }));
        console.log("Mapped response:", mappedResponse);
        return mappedResponse;
      })
      .catch((error) => {
        console.log("API error:", error);
        setErrorAlert("Something went wrong!");
        scrollToTop();
        const event = new Event("click", { bubbles: true });
        document
          .querySelector(
            "button.ln-c-button.ln-c-input-group__action, button[aria-label='close menu']"
          )
          ?.dispatchEvent(event);
        return Promise.resolve([]);
      });
  };


  const handleContractorSelect = async (val: ContractorSearchItem | null) => {
    if (val === null) {
      resetPage();
      setShowAccordion(false);
      setaccordionexpand(false);
      return;
    }
    try {
      setCompanyId(val.value);
      const userDetails = loaduserdetails(val.value);
      console.log(userDetails);
      setShowAccordion(true);
      setaccordionexpand(true);
    } catch (error) {
      console.error("Error fetching contractor user details:", error);
      setShowAccordion(false);
      setaccordionexpand(false);
      setErrorAlert("Something went wrong while loading contractor details!");
    }
  };


  const handleScrollModal = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  };


  const resetPage = (flag: boolean = true) => {
    setDisableDiscardSaveButton(true)
    // setSelectedContractor({
    //   id: "",
    //   contractorName: "",
    // } as ContractorManagementType);
    setaccordionexpand(false);
    setuserdetails([]);
    setErrorAlert(false);
    // const event = new Event("click", { bubbles: true });
    // document
    //   .querySelector(
    //     "button.ln-c-button.ln-c-input-group__action, button[aria-label='Clear the field']"
    //   )
    //   ?.dispatchEvent(event);
    // document
    //   .querySelector(
    //     "button.ln-c-button.ln-c-input-group__action, button[aria-label='close menu']"
    //   )
    //   ?.dispatchEvent(event);
  };
  const textInputOnChange = (event: React.ChangeEvent<HTMLInputElement>, index: number, type: string) => {
    setDisableDiscardSaveButton(false)
    const inputElement = event.target as HTMLInputElement;
    const element = event.target;
    const validValue = event.target.value;
    const userlist = [...userdetails];
    let itemindex = userlist.findIndex((i) => i.id === element.id);
    if (itemindex === -1) {
      console.log(`No user found with id ${element.id}`);
      return;
    }
    type FieldNames = keyof contractorContactsusers;

    switch (inputElement.name as FieldNames) {
      case "name":
        const pattern = /^[A-Za-z ]*$/;
        const inputElement = event.target as HTMLInputElement;
        const fieldName: keyof contractorContactsusers = inputElement.name as keyof contractorContactsusers;
        if (typeof fieldName === "string" && pattern.test(validValue)) {
          userlist[itemindex]["name"] = element.value.replace(
            /^\s+|\s+(?=\s)/g,
            ""
          );
          const tempArrayforInvalid = [...tempArrayforDuplicateName];
          setTempArrayforDuplicateName(
            tempArrayforInvalid.filter((s) => s !== event.target.id)
          );
        }
        break;
      case "email":
        if (validValue === "") {
          userlist[itemindex]["email"] = element.value;
        }
        // const emailTest = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        const emailTest = /^[A-Za-z0-9@.]+$/;
        if (emailTest.test(validValue)) {
          userlist[itemindex]["email"] = element.value;
        }
        break;
      case "notes":
        if (validValue === "") {
          userlist[itemindex]["notes"] = element.value;
        }
        // const emailTest = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        userlist[itemindex]["notes"] = element.value;
        break;
      case "phone":
        let inputValue = element.value;
        console.log(inputValue.length, inputValue);
        if (inputValue.length <= 5) {
          userlist[itemindex]["phone"] = inputValue.replace(/\D/g, "");
        }
        // else if (inputValue.length > 12) {
        //   console.log("value exceed");
        // }
        else {
          inputValue = `${inputValue
            .slice(0, 5)
            .replace(/\D/g, "")} ${inputValue.slice(6, 13).replace(/\D/g, "")}`;
          userlist[itemindex]["phone"] = inputValue;
        }
    }
    setuserdetails(userlist);
  };


  const fmaddRowToGrid = () => {
    setDisableDiscardSaveButton(false)
    const lastRecord = userdetails[userdetails.length - 1];
    let newguid = crypto.randomUUID();
    setuserdetails([
      ...userdetails,
      {
        id: newguid.toString(),
        companyHeaderId: lastRecord ? lastRecord.companyHeaderId : "",
        companyId: lastRecord ? lastRecord.companyId : "",
        companyUserId: newguid.toString(),
        contactLevel: null,
        email: "",
        level: "",
        name: "",
        notes: "",
        phone: "",
        isNew: 1,
      },
    ]);
  };


  const removeRowFromGrid = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    setDisableDiscardSaveButton(false)
    console.log(userdetails);
    let fmlist = [...userdetails]
    // .filter((fm) => fm.isNew !== 0); //Copies the scmdetails array and filters it to only include items where isNew is not equal to 0 (which likely indicates that the row is new and unsaved).
    const element = event.target as HTMLButtonElement; //: This refers to the DOM element that triggered the event, which in this case is the button the user clicked to remove a row from the grid.
    var foundIndex = fmlist.findIndex((x) => x.id === element.id);

    // fmlist = [...fmlist].filter((fm) => fm.isNew !== 0);
    let nameArray = [...tempArrayforDuplicateName].filter(
      (s) => s !== element.id
    );
    if (nameArray.length > 0) {
      const nameArray1 = [...nameArray];
      nameArray1.forEach((nameID, index) => {
        const foundIndex = fmlist.findIndex((i) => i.id === nameID);
        const tempName = fmlist[foundIndex].name.toLowerCase().trim();
        let c = 0;
        fmlist.forEach((array) => {
          if (array.name.toLowerCase().trim() === tempName) {
            c++;
          }
        });
        if (c <= 1) {
          nameArray = nameArray1.filter((s) => s !== nameID);
        }
      });
    }
    setTempArrayforDuplicateName(nameArray); //setTempArrayforDuplicateName(nameArray): Updates the state of tempArrayforDuplicateName with the newly filtered nameArray, which excludes non-duplicate names.
    if (duplicatename) {
      if (index === nameIndex) {
        setDuplicatename(false);
        // setDuplicateSCMname(false)
      }
    }
    if (phnIndex === index) {
      setValidFMNumber(false);
      // setValidSCMNumber(false)
    }
    else if (phnIndex > index) {
      setPhnIndx(phnIndex - 1);
    } else {
      setPhnIndx(phnIndex + 1);
    }
    if (indx === index) {
      setValidFMEmailAddress(false);
    } else if (indx > index) {
      setIndx(indx - 1);
    } else {
      setIndx(indx + 1);
    }

    if (fmlist[foundIndex].name !== "") {
      setErrorAlert(
        `You have removed ${fmlist[foundIndex].name}. To save the changes, please click on the Save button.`
      );
      scrollToTop();
    }
    if (fmlist[foundIndex]["isNew"] === 1) {
      fmlist.splice(foundIndex, 1);
    } else {
      fmlist[foundIndex]["isNew"] = 0;
    }
    console.log(fmlist);
    setuserdetails(fmlist);
    setSuccessAlert(false);
  };



  //The nameBlur function is designed to handle a blur event on a name input field within a grid or form. It checks if the name is valid (non-empty) and whether it's a duplicate among the list of names. If duplicates are found, it updates a temporary array that keeps track of duplicate names and adjusts the relevant state.
  const nameBlur = (
    event: React.FocusEvent<HTMLInputElement>,
    indx: number,
    type: string
  ) => {
    //Copies the userdetails array and filters out contractorContactsusers with isNew equal to 0, meaning only active (non-null) contractorContactsusers remain in userlist.
    const userlist = [...userdetails].filter((scm) => scm.isNew !== 0);
    const validValue = event.target.value.trim();//removing extra whitespace
    let tempArray = [...tempArrayforDuplicateName];
    //duplicateCount keeps track of the occurrences of validValue in userlist.
    let duplicateCount = 0;
    //If the user input is empty (e.g., they clicked on the name input and then left it empty),
    //the function sets invalidName to true and exits. This prevents further processing on an empty name.
    if (validValue === "") {
      setInvalidName(true);
      setInvalidNameIndex(indx);
      return;
    }

    const propertyName = "name";
    userlist.forEach((subArray, idx) => {
      if (
        subArray[propertyName]?.toLowerCase().trim() === validValue.toLowerCase()
      ) {
        duplicateCount++;
        if (duplicateCount > 1 && !tempArray.includes(event.target.id)) {
          tempArray.push(event.target.id);
        }
      }
    });

    const tempArray1 = [...tempArray];
    if (tempArray1.length > 0) {
      tempArray1.forEach((nameID) => {
        const foundIndex = userlist.findIndex((i) => i.id === nameID);
        if (foundIndex === -1) return; // Skip if not found

        const tempName = userlist[foundIndex]?.name?.toLowerCase().trim();
        if (tempName === undefined) return; // Skip if undefined

        let c = 0;
        userlist.forEach((array) => {
          if (array.name?.toLowerCase().trim() === tempName) {
            c++;
          }
        });

        if (c <= 1) {
          tempArray = tempArray.filter((s) => s !== nameID);
        }
      });
    }

    setTempArrayforDuplicateName(tempArray);
  };
  //The emailBlur function is designed to validate email input fields when they lose focus (on blur). Here’s a detailed explanation of its purpose and how it works:
  //The emailBlur function primarily validates whether an email address entered by the user conforms to a specific pattern, in this case, a corporate email domain (@sainsburys.co.uk). It updates the state to keep track of invalid email entries and handles the removal of invalid entries if the email becomes valid.

  const emailBlur = (event: any, idx: number, type: string) => {
    const validMail = event.target.value;
    const validString = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i;


    // If email field is empty, remove the ID from tempArrayforInvalidMail
    if (validMail.length === 0) {
      const tempArrayforInvalid = [...tempArrayforInvalidMail];
      setTempArrayforInvalidMail(
        tempArrayforInvalid.filter((s) => s !== event.target.id)
      );
      return;
    }

    // For "hfml" type
    if (type === "hfml") {
      // Check if the email matches the valid pattern
      if (validString.test(validMail)) {
        console.log("valid string ", validMail, idx);
        const tempArrayforInvalid = [...tempArrayforInvalidMail];
        setTempArrayforInvalidMail(
          tempArrayforInvalid.filter((s) => s !== event.target.id)
        );
      } else {
        // If invalid, add the ID to the invalid email array
        setTempArrayforInvalidMail((temp) => [...temp, event.target.id]);
      }
    } else {
      // For other types, validate similarly
      if (validString.test(validMail)) {
        const tempArrayforInvalid = [...tempArrayforInvalidMail];
        setTempArrayforInvalidMail(
          tempArrayforInvalid.filter((s) => s !== event.target.id)
        );
      } else {
        // If invalid, add the ID to the invalid email array
        setTempArrayforInvalidMail((temp) => [...temp, event.target.id]);
      }
    }
  };

  const phoneBlur = (event: any, idx: number, type: string) => {
    let validPhoneNumber = event.target.value || "";  // Default to an empty string if value is undefined
    if (validPhoneNumber.length === 0) {
      const tempArrayforInvalid = [...tempArrayforInvalidNumbers];
      setTempArrayforInvalidNumbers(
        tempArrayforInvalid.filter((s) => s !== event.target.id)
      );
      return;
    }
    validPhoneNumber = validPhoneNumber.replace(" ", "");
    if (validPhoneNumber.length < 11 || !/^\d+$/.test(validPhoneNumber)) {
      setTempArrayforInvalidNumbers((temp) => [...temp, event.target.id]);
    } else {
      const tempArrayforInvalid = [...tempArrayforInvalidNumbers];
      setTempArrayforInvalidNumbers(
        tempArrayforInvalid.filter((s) => s !== event.target.id)
      );
    }

  };

  const clearAlerts = () => {
    setErrorAlert(false);
    setSuccessAlert(false);
  };
  const handleSaveForm = () => {
    setModalState(false);
    console.log(userdetails)
    const temp = userdetails.map(user => ({
      Id: user.id,
      CompanyId: companyId,
      Name: user.name,
      Email: user.email,
      Phone: user.phone,
      Notes: user.notes,
      IsNew: (user.isNew === 0 || user.isNew === 1) ? user.isNew : null
    }))
    contractorContactsApi
      .addorupdatecompanyusers(apiClient, temp)
      .then((response: { ok: any; }) => {
        if (response.ok) {
          // loadusers(companyId);
          setSuccessAlert("User list have been updated successfully.");
          scrollToTop();
          setErrorAlert(false);
          // setaccordionexpand(false);
          setDisableDiscardSaveButton(true)
          loaduserdetails(companyId);
          setLoadPage(!loadPage)
        } else {
          throw new Error("Invalid response.");
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorAlert("User list update Failed.");
        setSuccessAlert(false);
        scrollToTop();
      });
  };
  const handleDiscardForm = () => {
    // setuserdetails(dummyUserDetails)
    setModalState(false);
    setErrorAlert(false);
    // scrollToTop()
    // setSuccessAlert('Changes have been successfully Discarded')
    setTempArrayforInvalidMail([]);
    setTempArrayforInvalidNumbers([]);
    setTempArrayforDuplicateName([]);
    loaduserdetails(companyId);
    setValidFMNumber(false);
    setValidFMEmailAddress(false);
    setDisableDiscardSaveButton(true)
    // setaccordionexpand(false);
    // setShowAccordion(false);
  };

  const handleSaveButtonClick = () => {
    setSuccessAlert(false);
    console.log(duplicatename, validFMEmailAddress, validFMNumber);
    if (!duplicatename && !validFMEmailAddress && !validFMNumber) {
      let emailValid = false;
      let phoneValid = false;
      let nameValid = false;
      const validString = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i;
      if (tempArrayforDuplicateName.length > 0) {
        nameValid = true;
      }
      // userdetails.forEach((scm) => {
      //   if (( scm.email && scm.email.length !== 0 ) || ( scm.phone && scm.phone.length !== 0) ){
      //     if (scm.email && !validString.test(scm.email) && scm.email.length !== 0) {
      //       emailValid = true;
      //     }
      //     if ((scm.phone.length < 12 && scm.phone.length !== 0) || /^\d+$/.test(scm.phone)) {
      //       phoneValid = true
      //     }
      //   }
      // });

      if (emailValid || phoneValid || nameValid) {
        scrollToTop();
        setErrorAlert("Some fields are invalid. Please verify1!");
        console.log("name", nameValid);
        console.log("email", emailValid);
        console.log("phone", phoneValid);
        return;
      }
      setModalState("saveusers");
    } else {
      scrollToTop();
      setErrorAlert("Some fields are invalid. Please verify2!");
    }
  };

  return (
    <>
      <ConfirmationModal
        showModal={modalState}
        onClose={() => setModalState(false)}
        onSave={handleSaveForm}
        onDiscard={handleDiscardForm}
      />
      <FormAlerts
        showError={errorAlert}
        showSuccess={successAlert}
        onClose={clearAlerts}
      />

      <div>
        <ContractorForm
          ref={myFormRef}
          onSave={handleSaveForm}
          errorAlert={errorAlert}
          successAlert={successAlert}
          onAlertClose={clearAlerts}
          onClear={() => resetPage(false)}
        >
          <ContractorFormField label="Contractor Name">
            <AsyncAutocompleteField
              // key={autocompleteKey}
              label=""
              role="search"
              className="formInput"
              name="contractor-search"
              onSelect={handleContractorSelect}
              onFocus={() => clearAlerts()}
              onChange={() => clearAlerts()}
              loadOptions={loadContractorSearch}
              icon={<Search />}
            />
          </ContractorFormField>
        </ContractorForm>
        {/* </Card> */}
      </div>
      <Card className="contractorForm">
        {showaccordion && (
          <>
            <h5>Add/Update contractor Contacts Users details </h5>
            <GridWrapper verticalAlign="center">
              {/* <div data-testid="accordion-multiple"> */}

              <Accordion
                defaultOpen={accordionexpand}
                title="List of Users"
                multipleOpen
                titleElement="h3"
              >
                {userdetails
                  .filter((x) => x.isNew !== 0)
                  .map((fmdetails, index) => {
                    return (
                      <>
                        <GridItem
                          className="formGridAlign"
                          size={{ xl: "1/5", ss: "1/5", default: "1/1" }}
                        >
                          <TextInputField
                            onFocus={() => clearAlerts()}
                            id={fmdetails.id}
                            value={fmdetails.name}
                            name="name"
                            placeholder='Name'
                            onBlur={(e: any) => nameBlur(e, index, "hfml")}
                            onChange={(e: any) =>
                              textInputOnChange(e, index, "hfml")
                            }
                            className="formInput"
                          />
                          {(tempArrayforDuplicateName.includes(fmdetails.id) ||
                            (duplicatename && index === nameIndex)) && (
                              <p
                                className="ln-c-field-info ln-c-field-info--error"
                                style={errorStyle}
                              >
                                {fmdetails.name} is already exist
                              </p>
                            )}
                        </GridItem>

                        <GridItem
                          className="formGridAlign"
                          size={{ xl: "1/5", ss: "1/5", default: "1/1" }}
                        >
                          <TextInputField
                            onFocus={() => clearAlerts()}
                            id={fmdetails.id}
                            value={fmdetails.email}
                            name="email"
                            onChange={(e: any) =>
                              textInputOnChange(e, index, "lscm")
                            }
                            onBlur={(e: any) => emailBlur(e, index, "hfml")}
                            placeholder="Email"
                            className="formInput"
                          />
                          {tempArrayforInvalidMail.includes(fmdetails.id) && (
                            <p
                              className="ln-c-field-info ln-c-field-info--error"
                              style={errorStyle}
                            >
                              Invalid (eg : abc123@sainsburys.co.uk)
                            </p>
                          )}
                        </GridItem>

                        <GridItem
                          className="formGridAlign"
                          size={{ xl: "1/5", ss: "1/5", default: "1/1" }}
                        >
                          <TextInput
                            onFocus={() => clearAlerts()}
                            id={fmdetails.id}
                            value={fmdetails.phone}
                            name="phone"
                            onChange={(e: any) =>
                              textInputOnChange(e, index, "lscm")
                            }
                            onBlur={(e: any) => phoneBlur(e, index, "hfml")}
                            placeholder="Phone"
                            className="formInput"
                          />
                          {(tempArrayforInvalidNumbers.includes(fmdetails.id) ||
                            (validFMNumber && index === phnIndex)) && (
                              <p
                                className="ln-c-field-info ln-c-field-info--error"
                                style={errorStyle}
                              >
                                Invalid Number ( eg : 99XXX XXXX99 )
                              </p>
                            )}
                        </GridItem>
                        <GridItem
                          className="formGridAlign"
                          size={{ xl: "1/5", ss: "1/5", default: "1/1" }}
                        >
                          <TextInputField
                            onFocus={() => clearAlerts()}
                            id={fmdetails.id}
                            value={fmdetails.notes}
                            name="notes"
                            placeholder='Notes'
                            onChange={(e: any) =>
                              textInputOnChange(e, index, "hfml")
                            }
                            className="formInput"
                          />
                        </GridItem>

                        <GridItem className='formGridAlign' size={{ xl: '1/5', ss: '1/5', default: '1/1' }}>
                          <div className='formButtonGroup'>
                            {userdetails.filter(x => x.isNew !== 0).length > 1 &&
                              <OutlinedButton id={fmdetails.id} size='60px' onClick={(e: React.MouseEvent<HTMLButtonElement>) => removeRowFromGrid(e, index)} className='gridRemoveRowButton' >Remove</OutlinedButton>
                            }
                            {userdetails.filter(x => x.isNew !== 0).length === (index + 1) &&
                              <FilledButton size='60px' onClick={fmaddRowToGrid} className='gridAddRowButton' >Add</FilledButton>
                            }
                          </div>
                        </GridItem>
                      </>
                    );
                  })}
              </Accordion>
              {/* </div> */}
              <>
                <GridItem size={{ xl: "1/5", ss: "1/3", default: "0" }}>
                  <></>
                </GridItem>
                <GridItem
                  size={{ xl: "1/3", ss: "2/3", default: "1/1" }}
                  className="formInputMaxWidth"
                >
                  <div className="formButtonGroup">
                    <OutlinedButton
                      style={{ width: "100%" }}
                      onClick={() => {
                        setModalState("discard");
                      }}
                      onScroll={handleScrollModal}
                      className="formDiscardButton"
                      disabled={disableDiscardSaveButton}
                    >
                      Discard
                    </OutlinedButton>
                    <FilledButton
                      style={{ width: "100%" }}
                      onClick={handleSaveButtonClick}
                      onScroll={handleScrollModal}
                      className="formSaveButton"
                      disabled={disableDiscardSaveButton}
                    >
                      Save
                    </FilledButton>
                  </div>
                </GridItem>
              </>
            </GridWrapper>
          </>
        )}
      </Card>
    </>
  );
};

export default Users;