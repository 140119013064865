import ApiClient from './ApiClient'
import { ContractorEscalation } from '../types/Entities/ContractorEscalation'
import { contractorContactsType, addEditContractorType, commonContractorsDropdownType, postContractorType, selectedOptionEntity, users, addorupdatecompanyusersType } from '../types/Entities/NewContractorType'

const findContractorContacts = (apiClient: ApiClient, searchKey: string):
  Promise<Array<ContractorEscalation>> => {
  return apiClient
    .get<Array<ContractorEscalation>>(`ContractorContacts/find?searchKey=${searchKey}`)
    .catch(error => { throw error })
}


const findContractorContactsCollection = (apiClient: ApiClient, searchKey: string):
  Promise<Array<commonContractorsDropdownType>> => {
  return apiClient
    .get<Array<commonContractorsDropdownType>>(`AdminContractorContact/getcompanycollection?searchKey=${searchKey}`)
    .catch(error => { throw error })
}

const getContractorContacts = async (apiClient: ApiClient, id: string):
  Promise<ContractorEscalation> => {
  return apiClient
    .get<ContractorEscalation>(`ContractorContacts/get?id=${id}`)
    .catch(error => { throw error })
}


const getContractorContactsDetails = async (apiClient: ApiClient, id: string):
  Promise<addEditContractorType> => {
  return apiClient
    .get<addEditContractorType>(`AdminContractorContact/getcontractcontactdetailsbyheaderid?headerId=${id}`)
    .catch(error => { throw error })
}

const getContractorDetailsByContractorId = async (apiClient: ApiClient, id: string):
  Promise<addEditContractorType> => {
  return apiClient
    .get<addEditContractorType>(`AdminContractorContact/checkandgetheaderidbycompanyid?companyid=${id}`)
    .catch(error => { throw error })
}


const getContacts = async (apiClient: ApiClient, id: string):
  Promise<contractorContactsType> => {
  return apiClient
    .get<contractorContactsType>(`AdminContractorContact/getcontractbycompanyid?companyId=${id}`)
    .catch(error => { throw error })
}


const updateContractorContacts = async (apiClient: ApiClient, payload: ContractorEscalation):
  Promise<Response> => {
  return apiClient
    .post('ContractorContacts', JSON.stringify(payload))
    .catch(error => { throw error })
}


const addContractorContacts = async (apiClient: ApiClient, payload: postContractorType):
  Promise<Response> => {
  return apiClient
    .post('AdminContractorContact/addcontractorcontact', JSON.stringify(payload))
    .catch(error => { throw error })
}

const editContractorContacts = async (apiClient: ApiClient, payload: postContractorType):
  Promise<Response> => {
  return apiClient
    .post('AdminContractorContact/updatecontractorcontact', JSON.stringify(payload))
    .catch(error => { throw error })
}

const getDetails = async (apiClient: ApiClient, contactName: string, companyId: string):
  Promise<contractorContactsType> => {
  return apiClient
    .get<contractorContactsType>(`AdminContractorContact/getdetailbycontractid?contactName=${contactName}&companyId=${companyId}`)
    .catch(error => { throw error })
}

const getContactUsers = async (apiclient: ApiClient, id: string):
  Promise<selectedOptionEntity> => {
  return apiclient
    .get<selectedOptionEntity>(`AdminContractorContact/getuserbycompanyid?companyId=${id}`)
    .catch(error => { throw error })
}

const getUserDetails = (apiClient: ApiClient, id: string):
  Promise<Array<users>> => {
  return apiClient
    .get<Array<users>>(`AdminContractorContact/getuserbycompanyid?companyId=${id}`)
    .catch(error => { throw error })
}

const addorupdatecompanyusers = async (apiClient: ApiClient, payload: addorupdatecompanyusersType[]):
  Promise<Response> => {
  return apiClient
    .post('CompanyUsers/addorupdatecompanyusers', JSON.stringify(payload))
    .catch(error => { throw error })
}

const contractorContactsApi = {
  getContractorContacts,
  findContractorContacts,
  updateContractorContacts,

  findContractorContactsCollection,
  getContacts,
  getDetails,
  getContactUsers,
  getContractorContactsDetails,
  addContractorContacts,
  editContractorContacts,
  getContractorDetailsByContractorId,

  getUserDetails,
  addorupdatecompanyusers,
}

export default contractorContactsApi