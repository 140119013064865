import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { Sainsburys } from '@jsluna/images'
import {
  Header,
  HeaderGlobalBar,
  HeaderGlobalItem,
  HeaderLogo,
  HeaderMainBar,
  HeaderNav,
  ListGroup,
  ListGroupItem,
  ListGroupDropdown,
} from '@jsluna/react'

import SignOutButton from '../SignOutButton'
import Constants from '../../../utils/constants'
import Feedback from '../../Feedback'

interface PageHeaderProps {
  roles: string[]
}

const PageHeader = ({ roles }: PageHeaderProps) => {
  const [width, setWidth] = useState(window.screen.width)
  const path = useLocation().pathname
  const feedback: any = useRef()

  const onResize = () => {
    setWidth(window.screen.width)
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)
    return (() => {
      window.removeEventListener('resize', onResize)
    })
  })

  const handleFeedbackClick = (event: any) => {
    event.preventDefault()
    feedback.current?.show()
  }
  const [activeCEscalation, setActiveCEscalation] = React.useState(false)
  const [activeSCM, setActiveSCM] = React.useState(false)
  const [activeWeekendOnCall, setActiveWeekendOnCall] = useState(false)
  const [hover, setHover] = React.useState(false)

  const generateTabs = () => {
    if (roles) {
      if (roles.includes(Constants.AdminRole)) {
        return <HeaderNav drawer label="Main">
          <NavLink className={`siteItem ${path === '/' && 'active'}`} to='/contractor-escalation'>Contractor Escalation</NavLink>
          <ListGroupDropdown className='siteItem'
            hover={activeCEscalation}
            onMouseEnter={(e: any) => { setActiveCEscalation(true) }}
            onMouseLeave={(e: any) => { setActiveCEscalation(false) }}
            onClose={(e: any) => { setActiveCEscalation(false) }}
            containerClassName='contracts'
          >
            Contractor Contacts
            <ListGroup>
              <ListGroupItem element="a" href="/User">
                User
              </ListGroupItem>
              <ListGroupItem element="a" href="/add-standard-contacts">
                Add Standard Contacts
              </ListGroupItem>
              <ListGroupItem element="a" href="/edit-standard-contacts">
                Edit Standard Contacts
              </ListGroupItem>
              <ListGroupItem element="a" href="/add-weekend">
                Add Weekend On-Call
              </ListGroupItem>
              <ListGroupItem element="a" href="/edit-weekend">
                Edit Weekend On-Call
              </ListGroupItem>
            </ListGroup>
          </ListGroupDropdown>
          <NavLink className='siteItem' to='/contractor-management'>Contractor Management</NavLink>
          <NavLink className='siteItem' to='/new-contractor'>New Contractor</NavLink>
          <ListGroupDropdown className='siteItem'
            // active={false}
            hover={activeSCM}
            // onClick={(e: { preventDefault: () => void }) => {
            //   setActiveSCM(!activeSCM )
            //   e.preventDefault()
            // }}
            containerClassName='scm'
            onMouseEnter={(e: any) => { setActiveSCM(true) }}
            onMouseLeave={(e: any) => { setActiveSCM(false) }}
            onClose={(e: any) => { setActiveSCM(false) }}
          >
            Site Care Manager
            <ListGroup>
              <ListGroupItem element='a' href='/assign-region'>
                Assign Regions
              </ListGroupItem>
              <ListGroupItem element='a' href='/SCMPeopleList'>
                People List
              </ListGroupItem>
              <ListGroupItem element="a" href="/weekendoncall">
                Weekend On-Call Users
              </ListGroupItem>
            </ListGroup>
          </ListGroupDropdown>
          <NavLink className='siteItem' to='/database-tables'>Database Tables</NavLink>
          <NavLink className='siteItem' to='/branch-locator'>Branch Locator</NavLink>
          <div className='siteItem'>
            <a href="" style={{ textDecoration: 'none' }} onClick={handleFeedbackClick}>Feedback</a>
          </div>
          {width < 721 ?
            <div className='siteItem'>
              <SignOutButton />
            </div> : <></>
          }
        </HeaderNav>
      }
      else if (roles.includes(Constants.ContractorManagerRole)) {
        return <HeaderNav drawer label="Main">
          {/* <NavLink className={`siteItem ${path === '/' && 'active'}`} to='/contractor-escalation'>Contractor Escalation</NavLink> */}
          <ListGroupDropdown className='siteItem'
            hover={activeCEscalation}
            onMouseEnter={(e: any) => { setActiveCEscalation(true) }}
            onMouseLeave={(e: any) => { setActiveCEscalation(false) }}
            onClose={(e: any) => { setActiveCEscalation(false) }}
            containerClassName='contracts'
          >
            Contractor Contacts
            <ListGroup>
              <ListGroupItem element="a" href="/User">
                User
              </ListGroupItem>
              <ListGroupItem element="a" href="/add-standard-contacts">
                Add Standard Contacts
              </ListGroupItem>
              <ListGroupItem element="a" href="/edit-standard-contacts">
                Edit Standard Contacts
              </ListGroupItem>
              <ListGroupItem element="a" href="/add-weekend">
                Add Weekend On-Call
              </ListGroupItem>
              <ListGroupItem element="a" href="/edit-weekend">
                Edit Weekend On-Call
              </ListGroupItem>
            </ListGroup>
          </ListGroupDropdown>
          <div className='siteItem'>
            <a href="" style={{ textDecoration: 'none' }} onClick={handleFeedbackClick}>Feedback</a>
          </div>
          {width < 721 ?
            <div className='siteItem'>
              <SignOutButton />
            </div> : <></>
          }
        </HeaderNav>
      }
    }
    else {
      return <HeaderNav drawer label="Main"></HeaderNav>
    }
  }

  return (
    <>
      <Feedback
        ref={feedback}
        headerText={'How was FM Core?'}
        bodyText={'We want to know how you found using FM Core today, so we can learn from your feedback and improve the tools you use.'}
      />
      <Header>
        <HeaderGlobalBar className='sainsbury'>
          <HeaderGlobalItem className='sainsburysItem'>
            <Sainsburys className='sainsburysLogo' aria-label="Sainsbury's logo" />
          </HeaderGlobalItem>
          <HeaderGlobalItem align='right'>
            <SignOutButton />
          </HeaderGlobalItem>
        </HeaderGlobalBar>
        <HeaderMainBar className='headerMainBar'>
          <HeaderLogo className='headerLogo'>
            FM Core
          </HeaderLogo>
          {generateTabs()}
        </HeaderMainBar>
      </Header>
    </>
  )
}

export default PageHeader