import ApiClient from './ApiClient'

import { UsersAndLevelList, AddComanyWeekendOnCall, DateTimeType, ServiceType, UserListType, SCMWeekendUsersList, postSCMWeekendUsersList } from '../types/Entities/WeekendOnCallType'

const getUsersAndLevels = async (apiClient: ApiClient, companyId: string): Promise<UsersAndLevelList> => {
    return apiClient
        .get<UsersAndLevelList>(`AdminWeekendOnCall/getuserandlevellist?companyId=${companyId}`)
        .catch(error => { throw error })
}

const getServices = (apiClient: ApiClient, companyId: string): Promise<ServiceType[]> => {
    return apiClient
        .get<ServiceType[]>(`AdminWeekendOnCall/getdetailbycompanyid?companyId=${companyId}`)
        .catch(error => { throw error })
}

const getDateAndTime = (apiClient: ApiClient, companyId: string, serviceName: string): Promise<DateTimeType[]> => {
    return apiClient
        .get<DateTimeType[]>(`AdminWeekendOnCall/getstartdateenddatebydetailname?companyId=${companyId}&detailName=${serviceName}`)
        .catch(error => { throw error })
}

const getUserDetails = (apiClient: ApiClient, date: string, serviceName: string, companyId:string): Promise<UserListType[]> => {
    return apiClient
        .get<UserListType[]>(`AdminWeekendOnCall/getuserdetailbydatetime?dates=${date}&detailName=${serviceName}&companyId=${companyId}`)
        .catch(error => { throw error })
}

const addComanyWeekendOnCall = async (apiClient: ApiClient, payload: AddComanyWeekendOnCall):
    Promise<Response> => {
    return apiClient
        .post('AdminWeekendOnCall/AddCompanyWeekendOnCall', JSON.stringify(payload))
        .catch(error => { throw error })
}
const updateComanyWeekendOnCall = async (apiClient: ApiClient, payload: AddComanyWeekendOnCall):
    Promise<Response> => {
    return apiClient
        .post('AdminWeekendOnCall/updatecompanyweekendoncall', JSON.stringify(payload))
        .catch(error => { throw error })
}


const getSCMWeekendUserDetails = (apiClient: ApiClient):
    Promise<Array<SCMWeekendUsersList>> => {
    return apiClient
        .get<Array<SCMWeekendUsersList>>('AdminWeekendOnCall/getsitecareoncalldetails?')
        .catch(error => { throw error })
}

const addorupdatesitecareoncall = (apiclient: ApiClient, payload: postSCMWeekendUsersList[]):
    Promise<Response> => {
    return apiclient
        .post('AdminWeekendOnCall/addorupdatesitecareoncall', JSON.stringify(payload))
        .catch(error => { throw error })
}
const weekendOnCallApi = {
    getUserDetails,
    getUsersAndLevels,
    getServices,
    addComanyWeekendOnCall,
    getDateAndTime,
    updateComanyWeekendOnCall,


    getSCMWeekendUserDetails,
    addorupdatesitecareoncall
}
export default weekendOnCallApi