import React, { useState, useImperativeHandle, PropsWithChildren, useMemo } from 'react'
import { FilledButton, OutlinedButton, TextInputField } from '@jsluna/react'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Switch } from '@jsluna/form'
import { Card } from '@jsluna/card'

import ContractorEscalationForm from './ContractorEscalationForm'
import ContractorFormField from './ContractorFormField'
import ConfirmationModal from './ConfirmationModal'
import FormAlerts from './FormAlerts'

import { ContractorFormData, EscalationsFormType, TextInputFormType } from '../../../types/FormTypes'
import { isEmail } from '../../../utils/helpers'

type Props = {
  ref: React.RefObject<typeof ContractorForm>
  contractorName?: boolean
  contractorEmail?: boolean
  managerEmail?: boolean
  contractorEscalations?: boolean
  automation?: boolean | 'disabled'
  onSave: (data: ContractorFormData) => void
  errorAlert?: false | string
  successAlert?: false | string
  onAlertClose?: () => void
  onClear?: () => void
}

const ContractorForm = React.forwardRef((props: PropsWithChildren<Props>, ref) => {
  const initialTextInput = { value: '', error: '' }
  const initialEscalations = [
    { value: [], error: '' },
    { value: [], error: '' },
    { value: [], error: '' },
    { value: [], error: '' },
    { value: [], error: '' }
  ]
  const [contractorName, setContractorName] = useState<TextInputFormType>(initialTextInput)
  const [contractorEmail, setContractorEmail] = useState<TextInputFormType>(initialTextInput)
  const [managerEmail, setManagerEmail] = useState<TextInputFormType>(initialTextInput)
  const [escalations, setEscalations] = useState<EscalationsFormType>(initialEscalations)
  const [automation, setAutomation] = useState(false)
  const [modalState, setModalState] = useState<'save' | 'discard' | false>(false)

  const clearForm = () => {
    setContractorEmail(initialTextInput)
    setContractorName(initialTextInput)
    setEscalations(initialEscalations)
    setManagerEmail(initialTextInput)
    setAutomation(false)
    if (props.onClear) props.onClear()
  }

  const isFormValid = () => {
    var result = true

    if (props.contractorEmail) {
      result = result && (contractorEmail.value === '' || (contractorEmail.value !== '' && isEmail(contractorEmail.value)))
    }

    if (props.contractorEscalations) {
      result = result && escalations.filter(escalation => escalation.value.length === 0).length === 0
    }

    if (props.managerEmail) {
      result = result && (managerEmail.value === '' || (managerEmail.value !== '' && isEmail(managerEmail.value)))
    }

    if (props.contractorName) {
      return (contractorName.value.trim() !== '')
    }

    return result
  }

  const validateEmail = (email: TextInputFormType, setEmail: (value: React.SetStateAction<TextInputFormType>) => void) => {
    if (email.value !== '' && !isEmail(email.value)) {
      setEmail(prevState => ({ ...prevState, error: 'Enter a valid email address.' }))
    }
  }

  const setEscalationValue = useMemo(() => (index: number, value: string[]) => {
    var currentEscalations = [...escalations]
    currentEscalations[index].value = value
    setEscalations(currentEscalations)
  }, [escalations, setEscalations])

  const setEscalationError = (index: number, error: string) => {
    var currentEscalations = [...escalations]
    currentEscalations[index].error = error
    setEscalations(currentEscalations)
  }

  const setErrors = () => {
    validateEmail(contractorEmail, setContractorEmail)
    validateEmail(managerEmail, setManagerEmail)
    if (props.contractorName) {
      if (contractorName.value.trim() === '') {
        setContractorName(prev => ({...prev, error: 'Contractor Name can\'t be empty.'}))
      }
    }
    else {
      escalations.forEach((escalation, index) => {
        if (escalation.value.length === 0) {
          setEscalationError(index, `Level ${index + 1} Email Contact can't be empty.`)
        }
      })
    }
    document.querySelector('div.ln-o-container.content')?.scrollTo(0, 0)
  }

  const handleSaveButtonClick = () => {
    if (isFormValid()) {
      setModalState('save')
    }
    else {
      setErrors()
    }
  }

  const handleSaveForm = () => {
    setModalState(false)
    props.onSave({ contractorName, contractorEmail, managerEmail, escalations, automation })
  }

  const handleDiscardForm = () => {
    setModalState(false)
    clearForm()
  }

  useImperativeHandle(ref, () => ({
    setContractorEmail, setEscalationValue, setManagerEmail, setAutomation, clearForm
  }), [contractorEmail, escalations, setEscalationValue])

  const handleScrollModal = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    })
  }

  const showButtons = props.contractorEmail || props.contractorEscalations || props.managerEmail || props.contractorName

  return (
    <>
      <ConfirmationModal
        showModal={modalState}
        onClose={() => setModalState(false)}
        onSave={handleSaveForm}
        onDiscard={handleDiscardForm}
      />
      {props.errorAlert && props.successAlert && props.onAlertClose &&
        <FormAlerts
          showError={props.errorAlert}
          showSuccess={props.successAlert}
          onClose={props.onAlertClose}
        />
      }
      <Card className='contractorForm' onFocus={props.onAlertClose}>
        <GridWrapper verticalAlign='center'>
          {props.children}

          {props.contractorName &&
            <ContractorFormField label='Contractor Name'>
              <TextInputField
                className='formInput' name='contractor-name' value={contractorName.value}
                error={contractorName.error} onFocus={() => setContractorName(prev => ({...prev, error: ''}))}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setContractorName({ value: e.target.value, error: '' })}
              />
            </ContractorFormField>
          }

          {props.contractorEmail &&
            <ContractorFormField label='Contractor Email'>
              <TextInputField
                className='formInput' name='contractor-email' value={contractorEmail.value}
                error={contractorEmail.error} onBlur={() => validateEmail(contractorEmail, setContractorEmail)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setContractorEmail({ value: e.target.value.trim(), error: '' })}
              />
            </ContractorFormField>
          }

          {props.contractorEscalations &&
            <ContractorEscalationForm
              escalations={escalations}
              onUpdateValue={setEscalationValue}
              onUpdateError={setEscalationError}
            />
          }

          {props.managerEmail &&
            <ContractorFormField label='Contractor Manager Email'>
              <TextInputField
                className='formInput' name='manager-email' value={managerEmail.value}
                error={managerEmail.error} onBlur={() => validateEmail(managerEmail, setManagerEmail)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setManagerEmail({ value: e.target.value.trim(), error: '' })}
              />
            </ContractorFormField>
          }

          {props.automation &&
            <ContractorFormField label='Automation'>
              <Switch
                name='contractor-automation' label='Automation' hideLabel inline
                onChange={() => setAutomation(prevalue => !prevalue)}
                disabled={props.automation === 'disabled'}
                checked={automation}
              />
            </ContractorFormField>
          }

          {showButtons &&
            <>
              <GridItem size={{ xl: '1/4', ss: '1/3', default: '0' }}><></></GridItem>
              <GridItem size={{ xl: '1/3', ss: '2/3', default: '1/1' }} className='formInputMaxWidth'>
                <div className='formButtonGroup'>
                  <OutlinedButton size='60px' className='formDiscardButton' onClick={() => setModalState('discard')} onScroll={handleScrollModal}>Discard</OutlinedButton>
                  <FilledButton size='60px' className='formSaveButton' onClick={handleSaveButtonClick} onScroll={handleScrollModal}>Save</FilledButton>
                </div>
              </GridItem>
            </>
          }
        </GridWrapper>
      </Card>
    </>
  )
})

export default ContractorForm