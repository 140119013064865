import * as React from 'react';
import CSS from "csstype";
import Modal from '../Common/Modal'
import { useEffect, useState, useRef } from "react";
import { useApiClient } from "../../context/AppContext";
import { GridWrapper, GridItem } from '@jsluna/grid'
import { FMRegionDetails, SCMDetails } from '../../types/Entities/SiteCareManager'
import siteCareManagerAPi from '../../api/siteCareManagerApi'
import { Card } from '@jsluna/card'
import { FilledButton, OutlinedButton, TextInputField, TextInput } from '@jsluna/react'
import { Accordion, AccordionItem } from '@jsluna/accordion'
import ConfirmationModal from '../Common/ContractorForm/ConfirmationModal'
import FormAlerts from '../Common/ContractorForm/FormAlerts'
// import { isEmail } from '../../../utils/helpers'
import { isEmail } from '../../utils/helpers'

const SCMPeopleList = () => {

  const errorStyle: CSS.Properties = {
    textAlign: "left",
    fontSize: "small",
    margin: 0
  }

  const [showAlert, setShowAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState<false | string>(false)
  const [successAlert, setSuccessAlert] = useState<false | string>(false)
  const [modalState, setModalState] = useState<'savescm' | 'discard' | false>(false)
  const [scmdetails, setscmdetails] = useState([] as SCMDetails[]);
  const [accordionexpand, setaccordionexpand] = useState<boolean>(false)
  const [validFMEmailAddress, setValidFMEmailAddress] = useState(false)
  const [validSCMEmailAddress, setValidSCMEmailAddress] = useState(false)
  const [validFMNumber, setValidFMNumber] = useState(false)
  const [validSCMNumber, setValidSCMNumber] = useState(false)
  const [duplicatename, setDuplicatename] = useState(false)
  const [duplicateSCMname, setDuplicateSCMname] = useState(false)
  const [duplicateEmailAddress, setDuplicateEmailAddress] = useState(false)
  const [invalidName, setInvalidName] = useState(false)
  const [indx, setIndx] = useState(0)
  const [phnIndex, setPhnIndx] = useState(0)
  const [nameIndex, setNameIndex] = useState(0)
  const [nameSCMIndex, setNameSCMIndex] = useState(0)
  const [invalidNameIndex, setInvalidNameIndex] = useState(0)
  const [tempArrayforInvalidNumbers, setTempArrayforInvalidNumbers] = useState<string[]>([])
  const [tempArrayforInvalidMail, setTempArrayforInvalidMail] = useState<string[]>([])
  const [tempArrayforDuplicateName, setTempArrayforDuplicateName] = useState<string[]>([])

  useEffect(() => {
    loadscmdusers();
    clearAlerts();
  }, []);

  const loadscmdusers = async () => {
    setscmdetails(await loadscmdetails())
  }
  const loadscmdetails = async (): Promise<SCMDetails[]> => {
    return siteCareManagerAPi
      .getSCMUsersDetails(apiClient)
      .then((response) =>
        response.map((item) => ({
          id: item.id,
          role: item.role,
          name: item.name,
          email: item.email,
          phone: item.phone,
          isNew: item.isNew
        }))
      )
      .catch(error => {
        console.log(error)
        setErrorAlert('Something went wrong!')
        const event = new Event('click', { bubbles: true })
        document.querySelector("button.ln-c-button.ln-c-input-group__action, button[aria-label='close menu']")?.dispatchEvent(event)
        return Promise.resolve([])
      })
  }
  const apiClient = useApiClient()
  const scrollToTop = () => document.querySelector('div.ln-o-container.content')?.scrollTo(0, 0)
  const handleScrollModal = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    })
  }

  const clearAlerts = () => {
    setErrorAlert(false);
    setSuccessAlert(false);
  }

  const handleDiscardForm = () => {
    // document.body.style.cursor = 'wait';
    // setTimeout(function() { document.body.style.cursor = 'default'}, 2000);
    setErrorAlert(false)
    setTempArrayforInvalidMail([]);
    setTempArrayforInvalidNumbers([]);
    setTempArrayforDuplicateName([]);
    setModalState(false);
    loadscmdusers();
    setaccordionexpand(false);
    setValidFMNumber(false)
    setValidSCMNumber(false)
    setValidFMEmailAddress(false)
    setValidSCMEmailAddress(false)
  }

  const handleSaveButtonClick = () => {
    setSuccessAlert(false)
    debugger;
    debugger;
    console.log(duplicatename, duplicateSCMname, validFMEmailAddress, validFMNumber, validSCMEmailAddress, validSCMNumber)
    if (!duplicateSCMname && !duplicatename && !validFMEmailAddress && !validFMNumber && !validSCMEmailAddress && !validSCMNumber) {

      let emailValid = false
      let phoneValid = false
      let nameValid = false
      const validString = /^[a-zA-Z]+\.?[a-zA-Z]*\.?[0-9]*@sainsburys\.co\.uk$/i;
      if (tempArrayforDuplicateName.length > 0) {
        nameValid = true
      }

      scmdetails.map((scm) => {
        debugger;
        if (scm.email.length != 0 || scm.phone.length != 0) {
          if (!validString.test(scm.email) && scm.email.length != 0) {
            emailValid = true
          } 
          if ((scm.phone.length < 12 && scm.phone.length != 0) || /^\d+$/.test(scm.phone)) {
            // setTempArrayforInvalidNumbers((temp) => [...temp, scm.id])
            phoneValid = true
          }
        }
      })
      if (emailValid || phoneValid || nameValid) {
        // scrollToTop();
        // setErrorAlert("Some fields are invalid. Please verify!")
        console.log(tempArrayforInvalidNumbers)
        return
      }
      setModalState('savescm');
    } else {
      // scrollToTop();
      // setErrorAlert("Some fields are invalid. Please verify!")
    }
  }

  const textInputOnChange = (event: any, index: number, type: string) => {
    console.log(event.target.id, event.target.role, event.target.name)
    const element = event.target;
    const validValue = event.target.value
    const validString = "@sainsburys.co.uk"

    const scmlist = [...scmdetails];
    let itemindex = scmlist.findIndex(i => i.id === element.id);

    switch (element.name) {
      case 'name':
        const pattern = /^[A-Za-z ]*$/;
        if (pattern.test(validValue)) {
          scmlist[itemindex]['name'] = element.value.replace(/^\s+|\s+(?=\s)/g, '');
          const tempArrayforInvalid = [...tempArrayforDuplicateName]
          setTempArrayforDuplicateName(tempArrayforInvalid.filter((s) => s != event.target.id))
        }
        break;
      case 'email':
        if (validValue === "") {
          scmlist[itemindex]['email'] = element.value;
        }
        const emailTest = /^[A-Za-z0-9@.]+$/;
        if (emailTest.test(validValue)) {
          scmlist[itemindex]['email'] = element.value;
        }
        break;
      case 'phone':
        let inputValue = element.value;
        console.log(inputValue.length, inputValue)
        if (inputValue.length <= 5) {
          scmlist[itemindex]['phone'] = inputValue.replace(/\D/g, "")
        } else if (inputValue.length > 12) {
          console.log("value exceed")
        } else {
          inputValue = `${inputValue.slice(0, 5).replace(/\D/g, '')} ${inputValue.slice(6, 13).replace(/\D/g, '')}`;
          scmlist[itemindex]["phone"] = inputValue
        }
    }
    setscmdetails(scmlist);
  }

  const emailBlur = (event: any, idx: number, type: string) => {
    const validMail = event.target.value
    const validString = /^[a-zA-Z]+\.?[a-zA-Z]*\.?[0-9]*@sainsburys\.co\.uk$/i;
    if (validMail.length === 0) {
      // setValidSCMEmailAddress(false)
      // setValidFMEmailAddress(false)
      const tempArrayforInvalid = [...tempArrayforInvalidMail]
      setTempArrayforInvalidMail(tempArrayforInvalid.filter((s) => s !== event.target.id))
      return
    }
    if (type === "hfml") {
      if (validString.test(validMail)) {
        console.log("valid string ", validMail, idx)
        const tempArrayforInvalid = [...tempArrayforInvalidMail]
        setTempArrayforInvalidMail(tempArrayforInvalid.filter((s) => s !== event.target.id))
      } else {
        setTempArrayforInvalidMail((temp) => [...temp, event.target.id])
      }
    }
    else {
      if (validString.test(validMail)) {
        // setIndx(idx)
        // setValidSCMEmailAddress(false)
        const tempArrayforInvalid = [...tempArrayforInvalidMail]
        setTempArrayforInvalidMail(tempArrayforInvalid.filter((s) => s !== event.target.id))
      } else {
        setTempArrayforInvalidMail((temp) => [...temp, event.target.id])
      }
    }
  }

  const phoneBlur = (event: any, idx: number, type: string) => {
    console.log(event.target.id, "invalid")
    let validPhoneNumber = event.target.value
    if (validPhoneNumber.length === 0) {
      // setValidSCMNumber(false)
      // setValidFMNumber(false)
      const tempArrayforInvalid = [...tempArrayforInvalidNumbers]
      setTempArrayforInvalidNumbers(tempArrayforInvalid.filter((s) => s !== event.target.id))
      return
    }
    validPhoneNumber = validPhoneNumber.replace(" ", "")
    if (type === "hfml") {
      console.log(/^\d+$/.test(validPhoneNumber))
      if (validPhoneNumber.length < 11 || !/^\d+$/.test(validPhoneNumber)) {
        setTempArrayforInvalidNumbers((temp) => [...temp, event.target.id])
      } else {
        const tempArrayforInvalid = [...tempArrayforInvalidNumbers]
        setTempArrayforInvalidNumbers(tempArrayforInvalid.filter((s) => s !== event.target.id))
      }
    } else {
      if (validPhoneNumber.length < 11 || !/^\d+$/.test(validPhoneNumber)) {
        setTempArrayforInvalidNumbers((temp) => [...temp, event.target.id])
      } else {
        const tempArrayforInvalid = [...tempArrayforInvalidNumbers]
        setTempArrayforInvalidNumbers(tempArrayforInvalid.filter((s) => s !== event.target.id))
      }
    }

  }

  const nameBlur = (event: any, indx: number, type: string) => {
    debugger;
    const scmlist = [...scmdetails].filter((scm) => scm.isNew != 0);
    const validValue = event.target.value.trim()
    let tempArray = [...tempArrayforDuplicateName]
    let duplicateCount = 0;
    if (validValue === "") {
      setInvalidName(true)
      setInvalidNameIndex(indx)
      return
    }
    scmlist.forEach((subArray, idx) => {
      if (subArray['name'].toLowerCase().trim() === validValue.toLowerCase()) {
        duplicateCount++;
        if (duplicateCount > 1 && !tempArray.includes(event.target.id)) {
          tempArray.push(event.target.id)
        }
      }
    });
    const tempArray1 = [...tempArray]
    if (tempArray1.length > 0) {
      tempArray1.forEach((nameID, index) => {
        const foundIndex = scmlist.findIndex(i => i.id === nameID);
        const tempName = scmlist[foundIndex].name.toLowerCase().trim()
        let c = 0;
        debugger;
        scmlist.forEach((array) => {
          if (array.name.toLowerCase().trim() === tempName) {
            c++;
          }
        })
        if (c <= 1) {
          tempArray = tempArray.filter((s) => s != nameID)
        }
      })
    }
    setTempArrayforDuplicateName(tempArray)
  }


  const addRowToGrid = () => {
    let newguid = crypto.randomUUID();
    setscmdetails([...scmdetails, { id: newguid.toString(), role: 'SCM', name: '', email: '', phone: '', isNew: 1 }])
  }

  const removeRowFromGrid = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    console.log(scmdetails)
    let fmlist = [...scmdetails].filter((fm) => fm.isNew != 0)
    const element = event.target as HTMLButtonElement;
    var foundIndex = fmlist.findIndex(x => x.id == element.id);
    

    fmlist = [...fmlist].filter((fm) => fm.isNew != 0)
    let nameArray = [...tempArrayforDuplicateName].filter((s) => s != element.id)
    debugger;
    if (nameArray.length > 0) {
      const nameArray1 = [...nameArray]
      nameArray1.forEach((nameID, index) => {
        const foundIndex = fmlist.findIndex(i => i.id === nameID);
        const tempName = fmlist[foundIndex].name.toLowerCase().trim()
        let c = 0;
        debugger;
        fmlist.forEach((array) => {
          if (array.name.toLowerCase().trim() === tempName) {
            c++;
          }
        })
        if (c <= 1) {
          nameArray = nameArray1.filter((s) => s != nameID)
        }
      })
    }
    setTempArrayforDuplicateName(nameArray)

    if (duplicateSCMname || duplicatename) {
      if (index === nameIndex) {
        setDuplicatename(false)
        setDuplicateSCMname(false)
      }
    }
    if (phnIndex === index) {
      setValidFMNumber(false)
      setValidSCMNumber(false)
    } else if (phnIndex > index) {
      setPhnIndx(phnIndex - 1)
    } else {
      setPhnIndx(phnIndex + 1)
    }
    if (indx == index) {
      setValidFMEmailAddress(false)
      setValidSCMEmailAddress(false)
    } else if (indx > index) {
      setIndx(indx - 1)
    } else {
      setIndx(indx + 1)
    }

    if (fmlist[foundIndex].name !== "") {
      setErrorAlert(`You have removed ${fmlist[foundIndex].name}. To save the changes, please click on the Save button.`)
      scrollToTop();
    }
    if (fmlist[foundIndex]['isNew'] === 1) {
      fmlist.splice(foundIndex, 1);
    }
    else {
      fmlist[foundIndex]['isNew'] = 0;

    }
    console.log(fmlist)
    setscmdetails(fmlist);
    setSuccessAlert(false)
  }

  const handleSaveForm = () => {
    // setErrorAlert(false)
    setModalState(false)
    debugger;
    siteCareManagerAPi
      .UpdateSCMUserDetails(apiClient, scmdetails)
      .then(response => {
        if (response.ok) {
          loadscmdusers();
          setSuccessAlert('People list have been updated successfully.')
          scrollToTop();
          setErrorAlert(false)
          setaccordionexpand(false);
        }
        else {
          throw new Error('Invalid response.')
        }
      })
      .catch(error => {
        console.log(error)
        setErrorAlert('People list update Failed.')
        setSuccessAlert(false)
        scrollToTop()
      })
  }

  const fmaddRowToGrid = () => {
    let newguid = crypto.randomUUID();
    setscmdetails([...scmdetails, { id: newguid.toString(), role: 'LSCM', name: '', email: '', phone: '', isNew: 1 }])
  }


  return (
    <>
      <ConfirmationModal
        showModal={modalState}
        onClose={() => setModalState(false)}
        onSave={handleSaveForm}
        onDiscard={handleDiscardForm}
      />

      <FormAlerts
        showError={errorAlert}
        showSuccess={successAlert}
        onClose={clearAlerts}
      />

      <Card className='contractorForm'>
        <h5>Add/Update site care manager details </h5>
        <GridWrapper verticalAlign='center'>
          <div data-testid="accordion-multiple">
            <Accordion defaultOpen={accordionexpand} title="Head of FM List" multipleOpen titleElement="h3">

              {
                scmdetails.filter(x => x.role === 'LSCM' && x.isNew != 0).map((fmdetails, index) => {
                  return (
                    <>
                      <GridItem className='formGridAlign' size={{ xl: '1/4', ss: '1/4', default: '1/1' }}>
                        <TextInputField onFocus={() => clearAlerts()} id={fmdetails.id} value={fmdetails.name} name='name'
                          onBlur={(e: any) => nameBlur(e, index, "hfml")}
                          onChange={(e: any) => textInputOnChange(e, index, "hfml")} className='formInput' />
                        {((tempArrayforDuplicateName.includes(fmdetails.id)) || (duplicatename && index === nameIndex)) &&
                          <p className="ln-c-field-info ln-c-field-info--error" style={errorStyle}>{fmdetails.name} is already exist</p>}
                      </GridItem>
                      <GridItem className='formGridAlign' size={{ xl: '1/4', ss: '1/4', default: '1/1' }}>
                        <TextInputField onFocus={() => clearAlerts()} id={fmdetails.id} value={fmdetails.email} name='email' onBlur={(e: any) => emailBlur(e, index, "hfml")} onChange={(e: any) => textInputOnChange(e, index, "lscm")} placeholder="Email" className='formInput' />
                        {((tempArrayforInvalidMail.includes(fmdetails.id)) ) &&
                          <p className="ln-c-field-info ln-c-field-info--error" style={errorStyle}>Invalid (eg : abc123@sainsburys.co.uk)</p>}
                      </GridItem>
                      <GridItem className='formGridAlign' size={{ xl: '1/4', ss: '1/4', default: '1/1' }}>
                        <TextInput onFocus={() => clearAlerts()} id={fmdetails.id} value={fmdetails.phone} name='phone' onBlur={(e: any) => phoneBlur(e, index, "hfml")} onChange={(e: any) => textInputOnChange(e, index, "lscm")} placeholder="Phone" className='formInput' />
                        {((tempArrayforInvalidNumbers.includes(fmdetails.id)) || (validFMNumber && index === phnIndex)) &&
                          <p className="ln-c-field-info ln-c-field-info--error" style={errorStyle}>Invalid Number ( eg : 99XXX XXXX99 )</p>
                        }
                      </GridItem>
                      <GridItem className='formGridAlign' size={{ xl: '1/4', ss: '1/4', default: '1/1' }}>
                        <div className='formButtonGroup'>
                          {scmdetails.filter(x => x.role === 'LSCM' && x.isNew != 0).length > 1 &&
                            <OutlinedButton id={fmdetails.id} size='60px' onClick={(e: React.MouseEvent<HTMLButtonElement>) => removeRowFromGrid(e, index)} className='gridRemoveRowButton' >Remove</OutlinedButton>
                          }
                          {scmdetails.filter(x => x.role === 'LSCM' && x.isNew != 0).length == (index + 1) &&
                            <FilledButton size='60px' onClick={fmaddRowToGrid} className='gridAddRowButton' >Add</FilledButton>
                          }
                        </div>
                      </GridItem>

                    </>
                  );
                })
              }


            </Accordion>

            <Accordion defaultOpen={accordionexpand} title="Site Care Manager List" multipleOpen titleElement="h3">

              {
                scmdetails.filter(x => x.role === 'SCM' && x.isNew != 0).map((fmdetails, index) => {
                  return (
                    <>
                      <GridItem className='formGridAlign' size={{ xl: '1/4', ss: '1/4', default: '1/1' }}>
                        <TextInputField id={fmdetails.id} value={fmdetails.name} name='name'
                          onBlur={(e: any) => nameBlur(e, index, "scm")}
                          onChange={(e: any) => textInputOnChange(e, index, "scm")} className='formInput' />
                        {((tempArrayforDuplicateName.includes(fmdetails.id)) || (duplicateSCMname && index === nameSCMIndex)) &&
                          <p className="ln-c-field-info ln-c-field-info--error" style={errorStyle}>{fmdetails.name} is already exist</p>}
                      </GridItem>
                      <GridItem className='formGridAlign' size={{ xl: '1/4', ss: '1/4', default: '1/1' }}>
                        <TextInputField id={fmdetails.id} value={fmdetails.email} name='email' onBlur={(e: any) => emailBlur(e, index, "hcml")} onChange={(e: any) => textInputOnChange(e, index, "scm")} placeholder="Email" className='formInput' />
                        {((tempArrayforInvalidMail.includes(fmdetails.id)) || (validSCMEmailAddress && index === indx)) &&
                          <p className="ln-c-field-info ln-c-field-info--error" style={errorStyle}>Invalid (eg : abc123@sainsburys.co.uk)</p>}
                      </GridItem>
                      <GridItem className='formGridAlign' size={{ xl: '1/4', ss: '1/4', default: '1/1' }}>
                        <TextInput id={fmdetails.id} value={fmdetails.phone} name='phone' onBlur={(e: any) => phoneBlur(e, index, "hcml")} onChange={(e: any) => textInputOnChange(e, index, "scm")} placeholder="Phone" className='formInput' />
                        {/* { validSCMNumber && index === phnIndex && */}
                        {((tempArrayforInvalidNumbers.includes(fmdetails.id)) || (validSCMNumber && index === phnIndex)) &&
                          <p className="ln-c-field-info ln-c-field-info--error" style={errorStyle}>Invalid Number ( eg : 99XXX XXXX99 )</p>}
                      </GridItem>
                      <GridItem className='formGridAlign' size={{ xl: '1/4', ss: '1/4', default: '1/1' }}>
                        <div className='formButtonGroup'>
                          {scmdetails.filter(x => x.role === 'SCM' && x.isNew != 0).length > 1 &&
                            <OutlinedButton id={fmdetails.id} size='60px' onClick={(e: React.MouseEvent<HTMLButtonElement>) => removeRowFromGrid(e, index)} className='gridRemoveRowButton' >Remove</OutlinedButton>
                          }
                          {scmdetails.filter(x => x.role === 'SCM' && x.isNew != 0).length == (index + 1) &&
                            <FilledButton size='60px' onClick={addRowToGrid} className='gridAddRowButton' >Add</FilledButton>
                          }
                        </div>
                      </GridItem>

                    </>
                  );
                })
              }

            </Accordion>
          </div>

          <>
            <GridItem size={{ xl: '1/4', ss: '1/3', default: '0' }}><></></GridItem>
            <GridItem size={{ xl: '1/3', ss: '2/3', default: '1/1' }} className='formInputMaxWidth'>
              <div className='formButtonGroup'>
                <OutlinedButton size='60px' onClick={() => { setModalState('discard') }} onScroll={handleScrollModal} className='formDiscardButton' >Discard</OutlinedButton>
                <FilledButton size='60px' onClick={handleSaveButtonClick} onScroll={handleScrollModal} className='formSaveButton' >Save</FilledButton>
              </div>
            </GridItem>
          </>

        </GridWrapper>
      </Card>
    </>
  )
}

export default SCMPeopleList