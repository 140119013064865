import ApiClient from './ApiClient'
import { FMRegionDetails, FMZoneDetails, SCMDetails } from '../types/Entities/SiteCareManager'
import { SCMWeekendUsersList } from '../types/Entities/WeekendOnCallType'

const getFMRegionDetails = async (apiClient: ApiClient, zoneid: string):
  Promise<FMRegionDetails> => {
  return apiClient
    .get<FMRegionDetails>(`SiteCareManager/getregionbyzoneid?zoneid=${zoneid}`)
    .catch(error => { throw error })
}

const getFMZoneDetails = (apiClient: ApiClient):
  Promise<Array<FMZoneDetails>> => {
  return apiClient
    .get<Array<FMZoneDetails>>(`SiteCareManager/getallzones?`)
    .catch(error => { throw error })
}

const getSCMUsersDetails = (apiClient: ApiClient):
  Promise<Array<SCMDetails>> => {
  return apiClient
    .get<Array<SCMDetails>>(`SiteCareManager/getscmusers?`)
    .catch(error => { throw error })
}

const UpdateSCMUserDetails = async (apiClient: ApiClient, payload: SCMDetails[]):
  Promise<Response> => {
  return apiClient
    .post('SiteCareManager/updatescmusers', JSON.stringify(payload))
    .catch(error => { throw error })
}

const UpdateRegionDetailsDetails = async (apiClient: ApiClient, payload: FMRegionDetails):
  Promise<Response> => {
  return apiClient
    .post('SiteCareManager/updateregiondetails', JSON.stringify(payload))
    .catch(error => { throw error })
}

const getSCMWeekendUserDetails = (apiClient: ApiClient):
  Promise<Array<SCMWeekendUsersList>> => {
  return apiClient
    .get<Array<SCMWeekendUsersList>>('WeekendOnCall/getsitecareoncalldetails?')
    .catch(error => { throw error })
}

const siteCareManagerApi = {
  getFMRegionDetails,
  getFMZoneDetails,
  getSCMUsersDetails,
  UpdateSCMUserDetails,
  UpdateRegionDetailsDetails,

  getSCMWeekendUserDetails
}

export default siteCareManagerApi